<template>
  <div>
    <v-app-bar dark color="secondary">
      <v-toolbar-title class="white--text">Stores</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="refreshPage">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn icon dark :to="{ name: 'Store', params: { store: 'new' } }">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-app-bar>

    <v-container>
      <v-card>
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
            hide-details></v-text-field>
        </v-card-title>
        <v-data-table :loading="loading" :headers="headers" :items="items" :search="search" :sort-by="['name']"
          :sort-desc="[false]" :items-per-page="-1" :footer-props="{
            'items-per-page-options': [10, 20, 50, -1],
            'items-per-page-text': 'Stores per page'
          }" item-key="_id" class="elevation-1">
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn small icon class="mr-2" :to="{ name: 'Store', params: { store: item._id } }">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn small icon class="mr-2" @click="duplicateStore(item)">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
            <v-btn small icon class="mr-2" @click="deleteItem(item._id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <!-- Add snackbar for feedback -->
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="3000">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "StoreList",
  props: ['retailer'],
  data() {
    return {
      loading: true,
      search: '',
      headers: [
        { text: "Store", value: "name" },
        { text: "Endpoint", value: "endpoint" },
        { text: "Access Level", value: "accessLevel" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
      snackbar: {
        show: false,
        text: '',
        color: 'success'
      }
    };
  },
  methods: {
    async fetchStores() {
      this.loading = true;
      try {
        const response = await axios.get(`https://api.safetelecom.net/api/stores?access=${this.retailer.accessLevel}`);
        this.items = response.data;
      } catch (error) {
        console.error("Error fetching stores:", error);
        this.showSnackbar("Error fetching stores", "error");
      } finally {
        this.loading = false;
      }
    },

    async duplicateStore(store) {
      this.loading = true;
      try {
        // Create a new store object without _id and with modified name
        const newStore = {
          ...store,
          _id: undefined,
          name: `${store.name} (Copy)`,
          endpoint: `${store.endpoint}-copy`,
          timestamp: Date.now()
        };

        // Create the new store
        const response = await axios.post('https://api.safetelecom.net/api/stores', newStore);
        const duplicatedStore = response.data;

        // If the original store had apps, copy them to the new store
        if (store.apps && store.apps.length > 0) {
          await axios.put(`https://api.safetelecom.net/api/stores/${duplicatedStore._id}/apps`, {
            apps: store.apps.map(app => typeof app === 'object' ? app._id : app)
          });
        }

        this.showSnackbar("Store duplicated successfully");
        await this.fetchStores();

        // Navigate to the new store
        this.$router.push({
          name: 'Store',
          params: { store: duplicatedStore._id }
        });
      } catch (error) {
        console.error("Error duplicating store:", error);
        this.showSnackbar("Error duplicating store", "error");
      } finally {
        this.loading = false;
      }
    },

    async deleteItem(id) {
      if (!confirm("Are you sure you want to delete this store?")) return;

      try {
        await axios.delete(`https://api.safetelecom.net/api/stores/${id}`);
        this.showSnackbar("Store deleted successfully");
        this.refreshPage();
      } catch (error) {
        console.error("Error deleting store:", error);
        this.showSnackbar("Error deleting store", "error");
      }
    },

    showSnackbar(text, color = 'success') {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },

    refreshPage() {
      this.fetchStores();
    },
  },
  created() {
    this.fetchStores();
  },
};
</script>