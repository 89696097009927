<template>
    <div>
        <v-toolbar dark color="secondary">
            <v-btn rounded dark text class="mr-2" :to="{ name: 'Urls' }"><v-icon>arrow_back</v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-btn dark text v-if="this.$route.params.id != 'new'"
                @click.native="deleteItem()"><v-icon>delete</v-icon></v-btn>
            <v-btn dark text @click.native="saveItem()"><v-icon>save</v-icon></v-btn>
        </v-toolbar>

        <v-card flat style="height: 80vh; overflow-y: scroll;">
            <v-container grid-list-xl>
                <v-layout wrap>
                    <v-flex xs12>
                        <v-text-field v-model="mUrl" label="URL" outlined clearable></v-text-field>
                        <v-btn color="primary" @click="parseUrl">Parse URL</v-btn>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field v-model="currentUrl.hostname" label="Host" outlined clearable></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field v-model="currentUrl.pathname" label="Path" outlined clearable></v-text-field>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>
    </div>
</template>
  
<script>
import axios from 'axios'

export default {
    name: 'UrlDetail',
    data() {
        return {
            editing: true,
            loading: true,
            currentUrl: {
                hostname: '',
                pathname: ''
            },
            mUrl: '',
        }
    },
    methods: {
        getUrl() {
            if (this.$route.params.id != "new") {
                axios.get(`https://api.safetelecom.net/api/browserwhitelist/${this.$route.params.id}`)
                    .then(response => {
                        this.currentUrl = response.data
                    })
            }
        },
        parseUrl() {
            if (this.mUrl != '') {
                console.log(this.mUrl)
                const url = new URL(this.mUrl)
                this.currentUrl.hostname = url.hostname
                this.currentUrl.pathname = url.pathname
            }
        },
        saveItem() {
            if (this.$route.params.id != "new") {
                axios.put(`https://api.safetelecom.net/api/browserwhitelist/${this.currentUrl._id}`, this.currentUrl).then(() => {
                    this.$router.push({ name: 'Urls' })
                })
            } else {
                axios.post(`https://api.safetelecom.net/api/browserwhitelist`, this.currentUrl).then(() => {
                    this.$router.push({ name: 'Urls' })
                })
            }
        },
        deleteItem() {
            confirm('Are you sure you want to delete this item?') && axios.delete(`https://api.safetelecom.net/api/browserwhitelist/${this.currentUrl._id}`).then(() => {
                this.$router.push({ name: 'Urls' })
            })
        }
    },
    created() {
        if (this.$route.params.id != "new") {
            this.editing = false
        }
        this.getUrl();
    },
};
</script>