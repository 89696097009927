<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-row align="center">
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              v-model="selectedCategory"
              :items="categories"
              label="Category Filter"
              clearable
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="5">
            <v-row>
              <v-col>
                <date-picker
                  v-model="startDate"
                  placeholder="Start Date"
                  :teleport="true"
                  :enable-time-picker="true"
                ></date-picker>
              </v-col>
              <v-col>
                <date-picker
                  v-model="endDate"
                  placeholder="End Date"
                  :teleport="true"
                  :enable-time-picker="true"
                ></date-picker>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="auditLogs"
        :search="search"
        :loading="loading"
        :sort-by="['timestamp']"
        :sort-desc="[true]"
        multi-sort
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ formatDate(item.timestamp) }}</td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ item.userName }}</span>
                </template>
                <span>User ID: {{ item.userId }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header class="py-1">
                    <div class="text-subtitle-2">
                      {{ summarizeChanges(item.changes) }}
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list dense>
                      <v-list-item v-for="(change, index) in item.changes" :key="index">
                        <v-list-item-content>
                          <v-list-item-title class="font-weight-medium">
                            {{ formatFieldName(change.field) }}
                            <v-chip
                              x-small
                              class="ml-2"
                              :color="getCategoryColor(change.category)"
                              text-color="white"
                            >
                              {{ change.category }}
                            </v-chip>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <span class="red--text text--lighten-1">
                              {{ formatValue(change.oldValue) }}
                            </span>
                            <v-icon small>mdi-arrow-right</v-icon>
                            <span class="green--text text--darken-1">
                              {{ formatValue(change.newValue) }}
                            </span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { clientApi } from '../services/api';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'ClientAuditHistory',
  
  components: {
    DatePicker
  },

  props: {
    clientId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      search: '',
      startDate: null,
      endDate: null,
      selectedCategory: null,
      auditLogs: [],
      headers: [
        { 
          text: 'Timestamp',
          value: 'timestamp',
          width: '200px'
        },
        { 
          text: 'User',
          value: 'userName',
          width: '200px'
        },
        { 
          text: 'Changes',
          value: 'changes',
          sortable: false,
          width: '100%'
        }
      ],
      categories: [
        'basic',
        'device',
        'apps',
        'features',
        'relationships',
        'settings',
        'status',
        'location',
        'system'
      ]
    };
  },

  watch: {
    clientId: 'fetchAuditLogs',
    startDate: 'fetchAuditLogs',
    endDate: 'fetchAuditLogs',
    selectedCategory: 'fetchAuditLogs'
  },

  methods: {
    async fetchAuditLogs() {
      this.loading = true;
      try {
        const params = {
          startDate: this.startDate,
          endDate: this.endDate,
          category: this.selectedCategory
        };

        const response = await clientApi.getClientAudit(this.clientId, params);
        this.auditLogs = response.data;
      } catch (error) {
        console.error('Error fetching audit logs:', error);
      } finally {
        this.loading = false;
      }
    },

    formatDate(date) {
      return moment(date).format('MM/DD/YYYY HH:mm:ss');
    },

    formatFieldName(field) {
      return field
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, str => str.toUpperCase());
    },

    formatValue(value) {
      if (value === null || value === undefined) return 'Not Set';
      if (typeof value === 'boolean') return value ? 'Yes' : 'No';
      if (Array.isArray(value)) return value.join(', ') || 'Empty List';
      return value.toString();
    },

    summarizeChanges(changes) {
      const categories = [...new Set(changes.map(c => c.category))];
      const count = changes.length;
      return `${count} change${count !== 1 ? 's' : ''} in ${categories.join(', ')}`;
    },

    getCategoryColor(category) {
      const colors = {
        basic: 'primary',
        device: 'deep-purple',
        apps: 'indigo',
        features: 'blue',
        relationships: 'cyan',
        settings: 'teal',
        status: 'green',
        location: 'orange',
        system: 'grey'
      };
      return colors[category] || 'primary';
    }
  },

  created() {
    this.fetchAuditLogs();
  }
};
</script>