<template>
    <v-card>
        <v-list two-line subheader>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Device Added</v-list-item-title>
                    <v-list-item-subtitle>{{ moment(client.lastUpdated).format('MMMM DD, YYYY h:mm a')
                        }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Device Age</v-list-item-title>
                    <v-list-item-subtitle>{{ moment.preciseDiff(Date.now(), client.lastUpdated)
                        }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Phone Number</v-list-item-title>
                    <v-list-item-subtitle>{{ client.phoneNumber ? new PhoneNumber(client.phoneNumber.toString(),
                        'US').toJSON()['number']['national'] : 'unset' }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Device (codename)</v-list-item-title>
                    <v-list-item-subtitle>{{ deviceName }} ({{ client.device }})</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Serial</v-list-item-title>
                    <v-list-item-subtitle>{{ client.serialNo }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Account Hash</v-list-item-title>
                    <v-list-item-subtitle>{{ client.accountHash }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>IMEI</v-list-item-title>
                    <v-list-item-subtitle>{{ client.imei }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Baseband</v-list-item-title>
                    <v-list-item-subtitle>{{ client.baseband }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Carrier (SIM)</v-list-item-title>
                    <v-list-item-subtitle>{{ client.carrier }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="carrierInfo">
                <v-list-item-content>
                    <v-list-item-title>Carrier (Network)</v-list-item-title>
                    <v-list-item-subtitle>{{ carrierInfo.network }} ({{ carrierInfo.country }})</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>MCC / MNC</v-list-item-title>
                    <v-list-item-subtitle>{{ client.mcc }} / {{ client.mnc }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>KosherOS Version</v-list-item-title>
                    <v-list-item-subtitle>
                        <span>{{ client.latestBuildDate ? moment(client.latestBuildDate).format('LL') : '' }}</span>
                        <v-chip v-if="updateStatus" 
                          class="ml-2" 
                          :color="updateStatus.color" 
                          small>
                          {{ updateStatus.text }}
                        </v-chip>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="latestUpdate">
                <v-list-item-content>
                    <v-list-item-title>Latest Available Update</v-list-item-title>
                    <v-list-item-subtitle>
                        {{ latestUpdate.version }} 
                        <v-chip v-if="!latestUpdate.published" color="purple" small class="ml-2">BETA</v-chip>
                        <span class="ml-2">({{ moment.unix(latestUpdate.datetime).format('LL') }})</span>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Last Check-In</v-list-item-title>
                    <v-list-item-subtitle>{{ moment(client.lastCheckIn).calendar(null, {
                        sameElse: 'M-D-YYYY h:mm:ss a'
                    }) }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
import moment from 'moment'
import * as momentDiff from 'moment-precise-range-plugin'
import PhoneNumber from 'awesome-phonenumber'
import { clientApi } from '../services/api'

export default {
    name: 'ClientDeviceStatus',
    props: {
        client: {
            type: Object,
            required: true
        },
        devices: {
            type: Array,
            default: () => []
        },
        carriers: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            moment,
            PhoneNumber,
            MomentDiff: momentDiff,
            updates: [],
            loading: false
        }
    },
    computed: {
        deviceName() {
            const device = this.devices.find(d => d.codename === this.client.device)
            return device ? device.name : 'unknown'
        },
        carrierInfo() {
            return this.carriers.find(carrier =>
                carrier.mcc === this.client.mcc && carrier.mnc === this.client.mnc
            )
        },
        latestUpdate() {
            if (!this.client.device || !this.updates.length) return null
            
            // Filter updates for this device and sort by datetime (newest first)
            const deviceUpdates = this.updates
                .filter(update => update.device === this.client.device)
                .sort((a, b) => b.datetime - a.datetime)
                
            return deviceUpdates.length > 0 ? deviceUpdates[0] : null
        },
        updateStatus() {
            if (!this.latestUpdate || !this.client.latestBuildDate) return null
            
            const clientBuildEpoch = moment(this.client.latestBuildDate).unix()
            const latestUpdateEpoch = this.latestUpdate.datetime
            
            if (clientBuildEpoch >= latestUpdateEpoch) {
                return { text: 'UP TO DATE', color: 'success' }
            } else {
                // More than 30 days out of date is considered critical
                const daysDiff = moment.unix(latestUpdateEpoch).diff(moment.unix(clientBuildEpoch), 'days')
                if (daysDiff > 30) {
                    return { text: 'UPDATE NEEDED', color: 'error' }
                } else {
                    return { text: 'UPDATE AVAILABLE', color: 'warning' }
                }
            }
        }
    },
    async created() {
        this.loading = true
        try {
            const response = await clientApi.getUpdates()
            this.updates = response.data
        } catch (error) {
            console.error('Error loading updates:', error)
        } finally {
            this.loading = false
        }
    }
}
</script>