<template>
  <v-container fluid class="pa-0">
    <v-card>
      <v-app-bar dark color="secondary">
        <v-btn icon @click="$router.push('/retailers')">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="isEditing" icon @click="deleteItem" class="mr-2">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn color="success" @click="saveItem" :loading="saving">
          <v-icon left>mdi-content-save</v-icon>
          Save
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-form ref="form" v-model="isFormValid" @submit.prevent="saveItem">
          <v-tabs v-model="activeTab" background-color="transparent" grow>
            <v-tab>Basic Information</v-tab>
            <v-tab>Contact & Address</v-tab>
            <v-tab>Auth0 Settings</v-tab>
            <v-tab>App Settings</v-tab>
            <v-tab>Store Selection</v-tab>
            <v-tab>Kiosk Settings</v-tab>
            <v-tab>Global Settings</v-tab>
          </v-tabs>

          <v-tabs-items v-model="activeTab">
            <!-- Basic Information Tab -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model.trim="currentItem.name" label="Name"
                        :rules="[v => !!v || 'Name is required']" required outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model.trim="currentItem.uid" label="Auth0 UID" disabled outlined dense
                        :hint="isEditing ? 'Cannot be changed' : 'Will be generated automatically'"
                        persistent-hint></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select v-model="currentItem.role" :items="roles" item-text="text" item-value="value"
                        label="Role" outlined dense :hint="'Current role permissions: ' + currentItem.role"
                        persistent-hint></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select v-model="currentItem.accessLevel" :items="accessLevels" item-text="text"
                        item-value="value" label="Access Level" outlined dense></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- Contact & Address Tab -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="currentItem.email" label="Email" type="email" @blur="emailToLowerCase"
                        outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="currentItem.phone" label="Phone Number" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="currentItem.website" label="Website" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="currentItem.address" label="Address" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="currentItem.mailAddress" label="Mail Address" outlined
                        dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="currentItem.mailCity" label="City" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="currentItem.mailState" label="State" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="currentItem.mailCountry" label="Country" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="currentItem.mailPostal" label="Postal Code" outlined dense></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- Auth0 Settings Tab -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-alert v-if="!needsAuth0User" type="info" dense outlined>
                    Auth0 user already exists for this retailer
                  </v-alert>
                  <v-row v-if="needsAuth0User">
                    <v-col cols="12" md="6">
                      <v-text-field v-model="auth0User.email" label="Auth0 Email" type="email"
                        :disabled="!needsAuth0User" outlined dense
                        :rules="[v => !!v || 'Email is required']"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="auth0User.password" label="Initial Password"
                        :type="showPassword ? 'text' : 'password'"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword" outlined dense
                        :rules="passwordRules"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- App Settings Tab -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-autocomplete v-model="currentItem.removedApps" :items="apps" item-text="metadata.name"
                    item-value="metadata.packageName" label="Excluded Apps" outlined chips multiple deletable-chips
                    :loading="loading"></v-autocomplete>

                  <v-autocomplete v-model="currentItem.activityBlocklist" :items="activities" item-text="description"
                    item-value="_id" label="Blocked Activities" outlined chips multiple deletable-chips
                    :loading="loading"></v-autocomplete>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- Store Selection Tab -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-autocomplete v-model="currentItem.stores" :items="stores" item-text="name" item-value="_id"
                    label="Available Stores" outlined chips multiple deletable-chips
                    :loading="loading"></v-autocomplete>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- Kiosk Settings Tab -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="currentItem.kioskProfile.appDwnUrl" label="App Download URL" outlined
                        dense></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="currentItem.kioskProfile.bootanimDwnUrl"
                        label="Boot Animation Download URL" outlined dense></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- Global Settings Tab -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-switch v-model="currentItem.globalSettings.disallowVideoPlayback" label="Disallow Video Playback"
                    color="primary"></v-switch>
                  <v-switch v-model="currentItem.globalSettings.autoEntitlement" label="Auto Entitlement"
                    color="primary"></v-switch>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
    </v-card>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="3000">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RetailerDetail',

  data() {
    return {
      isFormValid: true,
      loading: true,
      saving: false,
      activeTab: 0,
      apps: [],
      activities: [],
      stores: [],
      roles: [
        { text: 'Admin', value: 'admin' },
        { text: 'Audit', value: 'audit' }
      ],
      accessLevels: [
        { text: 'Phones Only', value: 'phones' },
        { text: 'All Access', value: 'all' }
      ],
      currentItem: {
        timestamp: null,
        version: 1,
        name: '',
        uid: '',
        address: '',
        licenses: 0,
        email: '',
        mailAddress: '',
        mailCity: '',
        mailState: '',
        mailCountry: '',
        mailPostal: '',
        notes: '',
        phone: '',
        website: '',
        role: 'admin',
        accessLevel: 'phones',
        removedApps: [],
        activityBlocklist: [],
        kioskProfile: {
          appDwnUrl: '',
          bootanimDwnUrl: '',
          bootanimation: ''
        },
        globalSettings: {
          disallowVideoPlayback: false,
          autoEntitlement: false
        },
        stores: []
      },
      auth0User: {
        email: '',
        password: '',
        connection: 'Username-Password-Authentication'
      },
      snackbar: {
        show: false,
        text: '',
        color: ''
      },
      showPassword: false,
      passwordRules: [
        v => !!v || 'Password is required',
        v => v.length >= 8 || 'Password must be at least 8 characters'
      ]
    };
  },

  computed: {
    formTitle() {
      return this.isEditing ? 'Edit Retailer' : 'New Retailer';
    },
    isEditing() {
      return this.$route.params.retailer !== 'new';
    },
    needsAuth0User() {
      return !this.isEditing || !this.currentItem.uid;
    }
  },

  methods: {
    showSnackbar(text, color = 'success') {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },

    emailToLowerCase() {
      if (this.currentItem.email) {
        this.currentItem.email = this.currentItem.email.toLowerCase();
        this.auth0User.email = this.currentItem.email;
      }
    },

    async createAuth0User() {
      try {
        const response = await axios.post('https://api.safetelecom.net/api/auth0/users', {
          email: this.auth0User.email,
          password: this.auth0User.password,
          role: this.currentItem.role,
          accessLevel: this.currentItem.accessLevel
        });

        return response.data.user_id;
      } catch (error) {
        console.error('Error creating Auth0 user:', error);
        throw new Error('Failed to create Auth0 user');
      }
    },

    async fetchRetailer(id) {
      try {
        const response = await axios.get(`https://api.safetelecom.net/api/retailers/${id}`);
        this.currentItem = {
          ...this.currentItem,
          ...response.data,
          globalSettings: {
            ...this.currentItem.globalSettings,
            ...(response.data.globalSettings || {})
          },
          kioskProfile: {
            ...this.currentItem.kioskProfile,
            ...(response.data.kioskProfile || {})
          }
        };
      } catch (error) {
        console.error('Error fetching retailer:', error);
        this.showSnackbar('Failed to fetch retailer details', 'error');
      }
    },

    async fetchApps() {
      try {
        const response = await axios.get('https://api.safetelecom.net/api/apps');
        this.apps = response.data.sort((a, b) =>
          a.metadata.name.localeCompare(b.metadata.name)
        );
      } catch (error) {
        console.error('Error fetching apps:', error);
        this.showSnackbar('Failed to fetch apps list', 'error');
      }
    },

    async fetchActivities() {
      try {
        const response = await axios.get('https://api.safetelecom.net/api/activities');
        this.activities = response.data.sort();
      } catch (error) {
        console.error('Error fetching activities:', error);
        this.showSnackbar('Failed to fetch activities list', 'error');
      } finally {
        this.loading = false;
      }
    },

    async fetchStores() {
      try {
        const response = await axios.get('https://api.safetelecom.net/api/stores');
        this.stores = response.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      } catch (error) {
        console.error('Error fetching stores:', error);
        this.showSnackbar('Failed to fetch stores list', 'error');
      }
    },

    async saveItem() {
      if (!this.$refs.form.validate()) return;

      this.saving = true;
      try {
        if (this.needsAuth0User) {
          const auth0UserId = await this.createAuth0User();
          this.currentItem.uid = auth0UserId;
        }

        if (this.isEditing) {
          await axios.put(`https://api.safetelecom.net/api/retailers/${this.currentItem._id}`, this.currentItem);
        } else {
          await axios.post('https://api.safetelecom.net/api/retailers', this.currentItem);
        }

        this.showSnackbar('Retailer saved successfully');
        this.$router.push('/retailers');
      } catch (error) {
        console.error('Error saving retailer:', error);
        this.showSnackbar('Failed to save retailer', 'error');
      } finally {
        this.saving = false;
      }
    },

    async deleteItem() {
      if (!confirm('Are you sure you want to delete this item?')) return;

      try {
        await axios.delete(`https://api.safetelecom.net/api/retailers/${this.currentItem._id}`);
        this.showSnackbar('Retailer deleted successfully');
        this.$router.push('/retailers');
      } catch (error) {
        console.error('Error deleting retailer:', error);
        this.showSnackbar('Failed to delete retailer', 'error');
      }
    }
  },

  watch: {
    'currentItem.email'(newValue) {
      if (this.needsAuth0User) {
        this.auth0User.email = newValue;
      }
    }
  },

  async created() {
    if (this.isEditing) {
      await this.fetchRetailer(this.$route.params.retailer);
    }
    await Promise.all([
      this.fetchApps(),
      this.fetchActivities(),
      this.fetchStores()
    ]);
  }
}
</script>