<template>
    <div>
        <v-card>
            <v-card-title>
                Send FCM Data Message
                <v-chip v-if="usingMockData" color="warning" class="ml-2" small>Using Mock Data</v-chip>
            </v-card-title>
            <v-card-text>
                <v-alert v-if="fcmTokenItems.length === 0" type="warning" dense>
                    No FCM tokens available for this client. FCM messages can only be sent to clients with registered tokens.
                </v-alert>
                
                <v-select v-model="selectedToken" :items="fcmTokenItems" label="Select FCM Token" item-text="label"
                    item-value="token" outlined :disabled="fcmTokenItems.length === 0"></v-select>
                <v-select v-model="selectedProject" :items="projectIds" label="Select Project ID" outlined></v-select>
                <v-text-field v-model="dataKey" label="Data Key" outlined hint="Common keys: action, refresh, sync"></v-text-field>
                <v-text-field v-model="dataValue" label="Data Value" outlined hint="Common values: true, sync, refresh"></v-text-field>
                
                <v-btn color="primary" @click="sendMessage" :disabled="!canSend">
                    Send FCM Data Message
                </v-btn>
                <v-btn color="secondary" text class="ml-2" @click="setQuickMessage('sync')">
                    Quick Sync
                </v-btn>
                <v-btn color="secondary" text @click="setQuickMessage('refresh')">
                    Quick Refresh
                </v-btn>
            </v-card-text>
        </v-card>
        <v-card class="mt-4" v-if="messageResult">
            <v-card-title>FCM Message Result</v-card-title>
            <v-card-text>
                <v-alert v-if="messageResult.mock" type="info" dense>
                    This is a simulated response. The actual message was not sent to the device.
                </v-alert>
                <pre>{{ JSON.stringify(messageResult, null, 2) }}</pre>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { clientApi } from '../services/api'

export default {
    name: 'ClientFcmMessage',

    props: {
        fcmTokens: {
            type: Array,
            default: () => []
        },
        projectIds: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            selectedToken: null,
            selectedProject: null,
            dataKey: 'action',
            dataValue: 'sync',
            messageResult: null,
            usingMockData: false
        }
    },

    computed: {
        fcmTokenItems() {
            return this.fcmTokens.map(token => ({
                label: `${token.app} - ${token.token.substr(0, 20)}...`,
                token: token.token
            }))
        },

        canSend() {
            return this.selectedToken &&
                this.selectedProject &&
                this.dataKey &&
                this.dataValue !== undefined
        }
    },

    methods: {
        async sendMessage() {
            try {
                const response = await clientApi.sendFcmMessage({
                    token: this.selectedToken,
                    projectId: this.selectedProject,
                    dataKey: this.dataKey,
                    dataValue: this.dataValue
                })
                this.messageResult = response.data
                this.usingMockData = !!response.data.mock
                this.$emit('message-sent', response.data)
            } catch (error) {
                console.error('Error sending FCM message:', error)
                this.messageResult = {
                    success: false,
                    error: error.response?.data?.error || error.message
                }
                this.$emit('message-error', error)
            }
        },
        
        setQuickMessage(type) {
            if (type === 'sync') {
                this.dataKey = 'action'
                this.dataValue = 'sync'
            } else if (type === 'refresh') {
                this.dataKey = 'action'
                this.dataValue = 'refresh'
            }
            
            // Auto-select token if only one is available
            if (!this.selectedToken && this.fcmTokenItems.length === 1) {
                this.selectedToken = this.fcmTokenItems[0].token
            }
            
            // Auto-select project if only one is available
            if (!this.selectedProject && this.projectIds.length === 1) {
                this.selectedProject = this.projectIds[0]
            }
        }
    },
    
    created() {
        // Check if we're using mock data
        this.usingMockData = this.projectIds.length === 1 && 
            this.projectIds[0] === 'default-fcm-project' || 
            this.projectIds.some(id => id.includes('safetelecom-'))
        
        // Auto-select first token and project ID if available
        if (this.fcmTokenItems.length > 0) {
            this.selectedToken = this.fcmTokenItems[0].token
        }
        
        if (this.projectIds.length > 0) {
            this.selectedProject = this.projectIds[0]
        }
        
        // Set default values
        this.dataKey = 'action'
        this.dataValue = 'sync'
    }
}
</script>