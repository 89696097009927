<template>
  <div>
    <v-app-bar dark color="secondary">
      <v-toolbar-title class="white--text">OTA Updates</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn rounded @click="refreshPage">
        <v-icon>refresh</v-icon>
      </v-btn>
      <v-btn rounded icon dark @click="openCreateDialog">
        <v-icon>add</v-icon>
      </v-btn>
    </v-app-bar>
    <v-container>
      <!-- Filters -->
      <v-row>
        <v-col cols="12" md="2">
          <v-text-field v-model="filters.search" label="Search" prepend-inner-icon="mdi-magnify" clearable
            hide-details class="mb-4"></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-model="filters.device" :items="uniqueDevices" label="Device" clearable hide-details
            class="mb-4"></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-model="filters.romtype" :items="uniqueRomTypes" label="ROM Type" clearable hide-details
            class="mb-4"></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-model="filters.published" :items="[
            { text: 'All', value: null },
            { text: 'Published', value: true },
            { text: 'Unpublished', value: false }
          ]" label="Status" hide-details class="mb-4"></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-model="filters.version" :items="uniqueVersions" label="Version" clearable hide-details
            class="mb-4"></v-select>
        </v-col>
        <v-col cols="12" md="2" class="d-flex align-center">
          <v-switch v-model="groupByDevice" label="Group by Device" hide-details dense color="primary"></v-switch>
        </v-col>
      </v-row>

      <!-- Data Table -->
      <v-card>
        <v-data-table
          dense
          :loading="loading"
          :headers="headers"
          :items="filteredUpdates"
          :sort-by="groupByDevice ? ['device', 'datetime'] : ['datetime']"
          :sort-desc="groupByDevice ? [false, true] : [true]"
          :items-per-page="15"
          item-key="_id"
          must-sort
          :group-by="groupByDevice ? 'device' : ''"
          :group-desc="false"
          show-group-by
          multi-sort
          class="elevation-1"
        >
          <template v-slot:[`item.published`]="{ item }">
            <v-switch v-model="item.published" color="success" hide-details @change="updateOta(item)" 
              dense></v-switch>
          </template>

          <template v-slot:[`item.datetime`]="{ item }">
            {{ formatDate(item.datetime) }}
          </template>

          <template v-slot:[`item.size`]="{ item }">
            {{ formatFileSize(item.size) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn small icon class="mr-2" @click="editItem(item)">
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn small icon class="mr-2" @click="confirmDelete(item)">
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <!-- Create/Edit Dialog -->
    <v-dialog v-model="dialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ isEditing ? 'Edit OTA' : 'New OTA' }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <!-- URL input for new OTA only -->
            <v-row v-if="!isEditing">
              <v-col cols="12">
                <v-text-field v-model="gcsUrl" label="GCS URL" :error-messages="urlError"
                  :rules="[v => !!v || 'URL is required']"
                  hint="Example: https://storage.googleapis.com/kosheros-store/updates/bramble-safetelecom-14.0.0-RELEASE-ota_update-2025.03.13.22-1741906077.zip"
                  persistent-hint @input="parseGcsUrl"></v-text-field>
              </v-col>
            </v-row>

            <!-- Display parsed info alert -->
            <v-row v-if="!isEditing && editedItem.filename">
              <v-col cols="12">
                <v-alert color="info" variant="tonal" :title="editedItem.filename" class="mb-4">
                  OTA information parsed successfully
                </v-alert>
              </v-col>
            </v-row>

            <!-- OTA Details Fields -->
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="editedItem.device" label="Device"
                  :rules="[v => !!v || 'Device is required']"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="editedItem.version" label="Version"
                  :rules="[v => !!v || 'Version is required']"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="editedItem.romtype" label="ROM Type"
                  :rules="[v => !!v || 'ROM Type is required']"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="editedItem.incrementalTimestamp" label="Incremental Timestamp"
                  :rules="[v => !!v || 'Timestamp is required']"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="editedItem.datetime" label="Date/Time"
                  :rules="[v => !!v || 'Date/Time is required']"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-model="editedItem.channel" :items="['retail']" label="Channel"
                  :rules="[v => !!v || 'Channel is required']"></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-switch v-model="editedItem.published" label="Published" color="success"></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" text @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn color="blue-darken-1" text @click="saveItem" :loading="saving"
            :disabled="!isValid">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title>Delete OTA Update</v-card-title>
        <v-card-text>
          Are you sure you want to delete this OTA update? This action cannot be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" text @click="closeDelete">
            Cancel
          </v-btn>
          <v-btn color="error" text @click="deleteItemConfirm" :loading="deleting">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { clientApi } from "@/services/api";

export default {
  name: "UpdateList",
  data() {
    return {
      loading: true,
      saving: false,
      deleting: false,
      moment: moment,
      dialog: false,
      deleteDialog: false,
      isEditing: false,
      gcsUrl: '',
      urlError: null,
      itemToDelete: null,
      groupByDevice: true,
      headers: [
        { 
          text: "Date", 
          value: "datetime",
          sort: (a, b) => parseInt(a) - parseInt(b)
        },
        { text: "Device", value: "device" },
        { text: "Timestamp", value: "incrementalTimestamp" },
        { text: "Version", value: "version" },
        { text: "ROM Type", value: "romtype" },
        { text: "Published", value: "published" },
        { 
          text: "Size", 
          value: "size",
          sort: (a, b) => parseInt(a) - parseInt(b)
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
      filters: {
        search: '',
        device: null,
        romtype: null,
        published: null,
        version: null
      },
      defaultItem: {
        published: false,
        channel: 'retail',
        device: '',
        filename: '',
        romtype: '',
        url: '',
        version: '',
        size: 0,
        incrementalTimestamp: '',
        datetime: null,
      },
      editedItem: {}
    };
  },
  computed: {
    uniqueDevices() {
      return [...new Set(this.items.map(item => item.device))].sort();
    },
    uniqueRomTypes() {
      return [...new Set(this.items.map(item => item.romtype))].sort();
    },
    uniqueVersions() {
      return [...new Set(this.items.map(item => item.version))].sort();
    },
    filteredUpdates() {
      return this.items.filter(item => {
        const searchMatch = !this.filters.search ||
          Object.values(item).some(val =>
            String(val).toLowerCase().includes(this.filters.search.toLowerCase())
          );

        const deviceMatch = !this.filters.device ||
          item.device === this.filters.device;

        const romTypeMatch = !this.filters.romtype ||
          item.romtype === this.filters.romtype;

        const publishedMatch = this.filters.published === null ||
          item.published === this.filters.published;
          
        const versionMatch = !this.filters.version ||
          item.version === this.filters.version;

        return searchMatch && deviceMatch && romTypeMatch && publishedMatch && versionMatch;
      });
    },
    isValid() {
      return this.editedItem.device &&
        this.editedItem.version &&
        this.editedItem.romtype &&
        this.editedItem.incrementalTimestamp &&
        this.editedItem.datetime;
    }
  },
  methods: {
    getItems() {
      this.loading = true;
      clientApi.getUpdates().then((response) => {
        this.items = response.data;
        this.loading = false;
      });
    },
    formatFileSize(bytes) {
      if (!bytes || bytes === 0) return '0 Bytes';
      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    },
    formatDate(unixTimestamp) {
      if (!unixTimestamp) return '';
      // Ensure we're working with a number
      const timestamp = parseInt(unixTimestamp);
      return this.moment(timestamp * 1000).calendar(null, { sameElse: "MM/DD/YYYY" });
    },
    async fetchFileSize(url) {
      try {
        // First approach: Try using HEAD request to get Content-Length
        const response = await fetch(url, { method: 'HEAD', mode: 'cors' });
        const contentLength = response.headers.get('Content-Length');
        
        if (contentLength) {
          return parseInt(contentLength, 10);
        }
        
        // If that fails, estimate size based on file naming convention or device type
        // This is a fallback approximation
        const filename = url.split('/').pop();
        const device = filename.split('-')[0];
        
        // Rough size estimates based on device type
        const sizeEstimates = {
          'redfin': 1500000000,   // ~1.5 GB
          'bramble': 1400000000,  // ~1.4 GB
          'sunfish': 1300000000,  // ~1.3 GB
          'default': 1200000000   // Default fallback ~1.2 GB
        };
        
        return sizeEstimates[device] || sizeEstimates['default'];
      } catch (error) {
        console.error('Error fetching file size:', error);
        // Return a default approximate size if all else fails
        return 1200000000; // Default to ~1.2 GB
      }
    },
    parseGcsUrl() {
      this.urlError = null;

      if (!this.gcsUrl) {
        return;
      }

      try {
        const url = new URL(this.gcsUrl);
        if (!url.pathname.startsWith('/kosheros-store/ota-updates/') && 
            !url.pathname.startsWith('/kosheros-store/updates/')) {
          this.urlError = 'Invalid GCS URL format';
          return;
        }

        const filename = url.pathname.split('/').pop();
        const parts = filename.split('-');

        if (parts.length < 7) {
          this.urlError = 'Invalid filename format in URL';
          return;
        }

        // Extract timestamp from the last part, which could have format like "1741906077.zip"
        const lastPart = parts[parts.length - 1];
        const timestamp = lastPart.split('.')[0];
        const timestampNum = parseInt(timestamp, 10);

        // OTA file format is generally: <device>-<vendor>-<version>-<romtype>-<suffix>-<date>-<timestamp>.zip
        this.editedItem = {
          ...this.defaultItem,
          filename,
          device: parts[0] || '',                  // First part is device codename
          version: parts[2] || '',                 // Third part is usually version
          romtype: parts[3] || '',                 // Fourth part is ROM type
          incrementalTimestamp: parts[5] || '',    // Second to last part is incremental timestamp
          datetime: timestampNum || Math.floor(Date.now() / 1000),  // Last part before .zip extension is unix timestamp
          url: this.gcsUrl,
          size: 0 // Will be filled by the server
        };
        
        // Fetch the file size from the GCS URL
        this.fetchFileSize(this.gcsUrl).then(size => {
          this.editedItem.size = size;
        }).catch(err => {
          console.error('Error fetching file size:', err);
        });
      } catch (err) {
        this.urlError = 'Invalid URL format';
        console.error('URL parse error:', err);
      }
    },
    editItem(item) {
      if (!item || !item._id) {
        console.error('Invalid item for editing:', item);
        return;
      }

      this.editedItem = { ...item };
      this.isEditing = true;
      this.dialog = true;
    },
    openCreateDialog() {
      this.editedItem = { ...this.defaultItem };
      this.gcsUrl = '';
      this.urlError = null;
      this.isEditing = false;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.gcsUrl = '';
        this.urlError = null;
      });
    },
    updateOta(item) {
      if (!item || !item._id) {
        console.error('Invalid item or missing ID:', item);
        return;
      }

      clientApi.updateUpdate(item._id, item)
        .then((response) => {
          // Update local data with response
          const index = this.items.findIndex(u => u._id === item._id);
          if (index !== -1) {
            this.items[index] = response.data;
          }
        })
        .catch((error) => {
          console.error('Failed to update OTA:', error);
          // Revert the switch if the update failed
          item.published = !item.published;
        });
    },
    saveItem() {
      if (!this.editedItem) {
        console.error('No item to save');
        return;
      }

      this.saving = true;
      try {
        if (this.isEditing && this.editedItem._id) {
          clientApi.updateUpdate(this.editedItem._id, this.editedItem)
            .then((response) => {
              const index = this.items.findIndex(item => item._id === this.editedItem._id);
              if (index !== -1) {
                this.items[index] = response.data;
              }
              this.closeDialog();
              this.saving = false;
            })
            .catch(error => {
              console.error('Save error:', error);
              this.saving = false;
            });
        } else {
          clientApi.createUpdate(this.editedItem)
            .then((response) => {
              this.items.push(response.data);
              this.closeDialog();
              this.saving = false;
            })
            .catch(error => {
              console.error('Save error:', error);
              this.saving = false;
            });
        }
      } catch (error) {
        console.error('Save error:', error);
        this.saving = false;
      }
    },
    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },
    closeDelete() {
      this.deleteDialog = false;
      this.$nextTick(() => {
        this.itemToDelete = null;
      });
    },
    deleteItemConfirm() {
      if (!this.itemToDelete || !this.itemToDelete._id) {
        return;
      }
      
      this.deleting = true;
      clientApi.deleteUpdate(this.itemToDelete._id)
        .then(() => {
          const index = this.items.findIndex(i => i._id === this.itemToDelete._id);
          if (index !== -1) {
            this.items.splice(index, 1);
          }
          this.closeDelete();
          this.deleting = false;
        })
        .catch(error => {
          console.error('Failed to delete item:', error);
          this.deleting = false;
        });
    },
    refreshPage() {
      this.items = [];
      this.getItems();
    },
  },
  created() {
    this.getItems();
    this.editedItem = { ...this.defaultItem };
  },
};
</script>

<style scoped>
.v-data-table {
  width: 100%;
}
</style>