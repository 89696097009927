<template>
  <div>
    <v-toolbar dark color="secondary">
      <v-btn dark icon to="/stores"><v-icon>mdi-arrow-left</v-icon></v-btn>
      <v-toolbar-title class="white--text">{{ storeTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="deleteItem" :disabled="isNewStore">
          <v-icon left>mdi-delete</v-icon>Delete
        </v-btn>
        <v-btn dark text @click="saveItem(false)">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
        <v-btn dark text @click="saveItem(true)">
          <v-icon left>mdi-content-save-edit</v-icon>Save and Continue
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-tabs grow icons-and-text background-color="primary" v-model="tabModel">
      <v-tab>Apps<v-icon>mdi-apps</v-icon></v-tab>
      <v-tab>Details<v-icon>mdi-information</v-icon></v-tab>
    </v-tabs>

    <v-container fluid>
      <v-card height="65rem" style="overflow-y: auto">
        <v-card-text>
          <v-tabs-items v-model="tabModel">
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    v-model="selectedApps"
                    :headers="headers"
                    :items="apps"
                    :loading="appsLoading"
                    :search="appSearch"
                    item-key="_id"
                    show-select
                    :sort-by="['metadata.name']"
                    :sort-desc="[false]"
                    :items-per-page="-1"
                    hide-default-footer
                    class="elevation-1"
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="appSearch"
                        label="Search Apps"
                        class="mx-4"
                        prepend-icon="mdi-magnify"
                      ></v-text-field>
                    </template>
                    <template v-slot:progress>
                      <v-progress-linear
                        indeterminate
                        color="primary"
                      ></v-progress-linear>
                    </template>
                    <template v-slot:[`item.metadata.icon`]="{ item }">
                      <v-avatar size="36" class="mr-2">
                        <v-img
                          v-if="item.metadata.icon"
                          :src="`//storage.googleapis.com/kosheros-store/icons/${item.metadata.icon}`"
                        ></v-img>
                        <v-icon v-else>mdi-application</v-icon>
                      </v-avatar>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-form ref="form" v-model="isFormValid">
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="storeName"
                          label="Name"
                          outlined
                          :rules="[v => !!v || 'Name is required']"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="storeEndpoint"
                          label="Endpoint"
                          outlined
                          :rules="[v => !!v || 'Endpoint is required']"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="storeAccessLevel"
                          label="Access Level"
                          outlined
                          :rules="[v => !!v || 'Access Level is required']"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          v-model="storeDescription"
                          label="Description"
                          outlined
                          rows="4"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-container>

    <v-snackbar v-model="snackbar" :color="snackbarColor" timeout="3000" top>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "StoreDetail",
  data() {
    return {
      loading: false,
      appsLoading: false,
      currentItem: null,
      isFormValid: true,
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
      tabModel: 0,
      apps: [],
      selectedApps: [],
      appSearch: '',
      headers: [
        { text: 'Icon', value: 'metadata.icon', sortable: false },
        { text: 'Name', value: 'metadata.name' },
        { text: 'Package Name', value: 'metadata.packageName' },
      ],
    };
  },
  computed: {
    isNewStore() {
      return this.$attrs.store === "new";
    },
    storeId() {
      return this.$attrs.store;
    },
    storeTitle() {
      return this.currentItem?.name || 'New Store';
    },
    storeName: {
      get() {
        return this.currentItem?.name || '';
      },
      set(value) {
        if (this.currentItem) this.currentItem.name = value;
      }
    },
    storeEndpoint: {
      get() {
        return this.currentItem?.endpoint || '';
      },
      set(value) {
        if (this.currentItem) this.currentItem.endpoint = value;
      }
    },
    storeAccessLevel: {
      get() {
        return this.currentItem?.accessLevel || '';
      },
      set(value) {
        if (this.currentItem) this.currentItem.accessLevel = value;
      }
    },
    storeDescription: {
      get() {
        return this.currentItem?.description || '';
      },
      set(value) {
        if (this.currentItem) this.currentItem.description = value;
      }
    }
  },
  methods: {
    async getCurrentItem() {
      this.loading = true;
      try {
        if (this.isNewStore) {
          this.currentItem = {
            name: '',
            endpoint: '',
            accessLevel: '',
            description: '',
            apps: [],
          };
        } else {
          const response = await axios.get(`https://api.safetelecom.net/api/stores/${this.storeId}`);
          this.currentItem = response.data;
          this.selectedApps = this.currentItem.apps || [];
        }
        await this.getApps();
      } catch (error) {
        console.error("Error fetching store details:", error);
        this.showSnackbar('Error loading store details', 'error');
      } finally {
        this.loading = false;
      }
    },
    async getApps() {
      this.appsLoading = true;
      try {
        const response = await axios.get('https://api.safetelecom.net/api/apps');
        this.apps = response.data.sort((a, b) => 
          a.metadata.name.localeCompare(b.metadata.name)
        );
      } catch (error) {
        console.error("Error fetching apps:", error);
        this.showSnackbar('Error loading apps', 'error');
      } finally {
        this.appsLoading = false;
      }
    },

    async saveItem(stayOnPage = false) {
      this.loading = true;
      try {
        let response;
        if (this.isNewStore) {
          response = await axios.post(`https://api.safetelecom.net/api/stores`, this.currentItem);
          this.showSnackbar('Store created successfully');
        } else {
          response = await axios.put(`https://api.safetelecom.net/api/stores/${this.storeId}`, this.currentItem);
          this.showSnackbar('Store updated successfully');
        }

        if (response && response.data) {
          this.currentItem = response.data;
        }

        // Update apps relationship
        await axios.put(`https://api.safetelecom.net/api/stores/${this.currentItem._id}/apps`, {
          apps: this.selectedApps.map(app => app._id)
        });

        if (!stayOnPage) {
          this.$router.push("/stores");
        } else if (this.isNewStore) {
          this.$router.replace(`/stores/${this.currentItem._id}`);
        }
      } catch (error) {
        console.error("Error saving store:", error);
        this.showSnackbar('Error saving store', 'error');
      } finally {
        this.loading = false;
      }
    },

    async deleteItem() {
      if (this.isNewStore) return;
      if (!confirm("Are you sure you want to delete this store?")) return;

      this.loading = true;
      try {
        await axios.delete(`https://api.safetelecom.net/api/stores/${this.storeId}`);
        this.showSnackbar('Store deleted successfully');
        this.$router.push("/stores");
      } catch (error) {
        console.error("Error deleting store:", error);
        this.showSnackbar('Error deleting store', 'error');
      } finally {
        this.loading = false;
      }
    },
    showSnackbar(text, color = 'success') {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    },
  },
  created() {
    this.getCurrentItem();
  },
  watch: {
    '$attrs.store': {
      immediate: true,
      handler() {
        this.getCurrentItem();
      }
    }
  }
};
</script>