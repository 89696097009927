<template>
    <v-dialog v-model="dialog" max-width="700">
        <v-card>
            <v-card-title>
                Import Clients
            </v-card-title>
            <v-card-text>
                <v-data-table dense :items="parsedData" :headers="this.headers" :items-per-page="10" >
                </v-data-table>

            </v-card-text>
            <v-form ref="form">
                <v-card-text>
                    <v-card-title>
                        Template
                    </v-card-title>
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-text-field outlined v-model="name" label="Name" required
                                :rules="[() => !!this.name || 'This field is required']"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field outlined v-model="email" label="Email"></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-select v-model="store" :items="stores" item-text="name" item-value="_id" label="Store"
                                outlined required></v-select>
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-select v-model="retailer" :items="retailers" item-text="name" item-value="_id"
                                label="Retailer" outlined required></v-select>
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-select v-model="auditor" :items="auditors" item-text="name" item-value="_id" label="Auditor"
                                outlined required></v-select>
                        </v-flex>
                        <v-row justify="space-around">
                            <v-flex xs12 md2>
                                <v-switch label="Active Account" v-model="isActive"></v-switch>
                            </v-flex>
                            <v-flex xs12 md2>
                                <v-switch label="Include Email" v-model="emailPackage"></v-switch>
                            </v-flex>
                            <v-flex xs12 md2>
                                <v-switch label="Include WhatsApp" v-model="whatsApp"></v-switch>
                            </v-flex>
                            <v-flex xs12 md2>
                                <v-switch label="Beta Tester" v-model="betaTester"></v-switch>
                            </v-flex>
                        </v-row>
                    </v-layout>
                </v-card-text>
            </v-form>
            <v-card-actions>
                <v-btn @click="submitForm" :color="loading ? 'normal' : 'primary'" :loading="loading">Submit</v-btn>
                <v-btn @click="closeDialog">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
    props: {
        parsedData: Array
    },
    data() {
        return {
            dialog: true,
            loading: false,
            stores: [],
            retailers: [],
            auditors: [],
            headers: [{ text: 'Serial Number', value: 'serial' }, { text: 'IMEI', value: 'imei' }],
            name: '',
            email: '',
            emailPackage: false,
            isActive: true,
            betaTester: false,
            whatsApp: false,
            retailer: "5af7bd22246f50235ca7698d",
            auditor: "",
            store: "5a0d9be20823dc23dfcacc18"
        };
    },
    computed: {
        isFormValid() {
            return this.$refs.form.validate();
        }
    },
    methods: {
        getStores() {
            axios.get(`https://api.safetelecom.net/api/stores?access=${this.$parent.retailer?.accessLevel || ''}`)
                .then(response => {
                    this.stores = response.data
                })
        },
        getRetailers() {
            axios.get(`https://api.safetelecom.net/api/retailers`)
                .then(response => {
                    this.retailers = response.data.sort((a, b) => {
                        if (a.name < b.name) { return -1 }
                        if (a.name > b.name) { return 1 }
                        return 0
                    })
                })
        },
        getAuditors() {
            axios.get(`https://api.safetelecom.net/api/auditors`)
                .then(response => {
                    this.auditors = response.data.sort((a, b) => {
                        if (a.name < b.name) { return -1 }
                        if (a.name > b.name) { return 1 }
                        return 0
                    })
                })
        },
        submitForm() {
            if (this.isFormValid) {
                this.loading = true
                let errorClients = []

                this.parsedData.forEach(item => {
                    const client = {
                        name: this.name,
                        email: this.email,
                        serialNo: item.serial,
                        imei: item.imei ? item.imei : '',
                        emailPackage: this.emailPackage,
                        isActive: this.isActive,
                        betaTester: this.betaTester,
                        whatsApp: this.whatsApp,
                        retailer: this.retailer,
                        audtor: this.auditor,
                        store: this.store
                    }

                    axios.post(`https://api.safetelecom.net/api/clients`, client).then(response => {
                        if (response.status !== 200) {
                            alert('There has been an error with your request. Please contact us for support.')
                            errorClients.push(client.serialNo)
                        }
                    })
                });

                if (errorClients.length > 0) {
                    alert(`The following clients were not saved:\n${errorClients.toString()}`)
                }
                else {
                    alert('Import successful!')
                }
                this.loading = false
                this.$emit('submit');
                this.closeDialog();
            }
        },
        closeDialog() {
            this.dialog = false;
            this.$emit('clear');
        }
    },
    created() {
        this.getStores()
        this.getRetailers()
        this.getAuditors()
    }
};
</script>