<template>
	<div>
		<v-toolbar dark color="secondary">
			<v-toolbar-title class="white--text">Clients</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-menu offset-y :close-on-content-click="false">
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon rounded dark v-bind="attrs" v-on="on">
						<v-icon>mdi-table-edit</v-icon>
					</v-btn>
				</template>
				<v-card scrollable>
                        <v-card-title>Column Selection</v-card-title>
                        <v-card-text style="height: 500px; overflow-y: scroll">
                            <v-list>
                                <v-list-item v-for="header in headers" :key="header.value">
                                    <v-checkbox v-model="selectedHeaders" :label="header.text" :value="header.value"
                                        :disabled="header.value === 'serialNo'" density="compact"></v-checkbox>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
			</v-menu>
			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-btn icon v-on="on">
						<json-csv :data="formattedDataForExport" :fields="fieldsForExport" name="clients.csv">
							<v-icon>mdi-file-download-outline</v-icon>
						</json-csv>		
					</v-btn>
				</template>
				Download CSV
			</v-tooltip>

			<v-btn icon rounded dark @click="refreshPage">
				<v-icon>refresh</v-icon>
			</v-btn>
			<v-btn icon rounded dark :to="{ name: 'Client', params: { id: 'new' } }">
				<v-icon>add</v-icon>
			</v-btn>
		</v-toolbar>
		<v-expansion-panels v-model="filterPanel">
			<v-expansion-panel>
				<v-expansion-panel-header>Filter</v-expansion-panel-header>
				<v-expansion-panel-content>
					<v-card>
						<v-card-text>
							<v-container fluid grid-list-lg>
								<v-layout row wrap>
									<v-flex xs12 md2>
										<v-text-field v-model="serialSearch" hide-details clearable outlined label="Serial Number" single-line></v-text-field>
									</v-flex>
									<v-flex xs12 md3>
										<v-text-field v-model="nameSearch" hide-details	clearable outlined label="Name" single-line></v-text-field>
									</v-flex>
									<v-flex xs12 md2>
										<v-text-field v-model="phoneSearch" hide-details clearable outlined label="Phone Number" single-line></v-text-field>
									</v-flex>
									<v-flex xs12 md2>
										<v-text-field v-model="emailSearch" hide-details clearable outlined label="Email" single-line></v-text-field>
									</v-flex>
									<v-flex xs12 md2>
										<v-text-field v-model="imeiSearch" hide-details clearable outlined label="IMEI" single-line></v-text-field>
									</v-flex>
									<v-flex xs12 md3>
										<v-select single-line clearable outlined v-model="retailerSearch" :items="retailers" item-text="name" item-value="name" label="Retailer"></v-select>
									</v-flex>
									<v-flex xs12 md3>
										<v-select single-line clearable outlined v-model="auditorSearch" :items="auditors" item-text="name" item-value="name" label="Auditor"></v-select>
									</v-flex>
									<v-flex xs12 md3>
										<v-select single-line clearable outlined v-model="storeSearch" :items="stores" item-text="name" item-value="name" label="App Store"></v-select>
									</v-flex>
									<v-flex xs6 md1>
										<v-text-field v-model="mccSearch" hide-details clearable outlined label="MCC" single-line></v-text-field>
									</v-flex>
									<v-flex xs6 md1>
										<v-text-field v-model="mncSearch" hide-details clearable outlined label="MNC" single-line></v-text-field>
									</v-flex>
									<v-flex xs12 md2>
										<v-text-field v-model="deviceSearch" hide-details clearable	outlined label="Device Name" single-line></v-text-field>
									</v-flex>
									<v-flex xs12 md3>
										<date-picker v-model="startDateSearch" placeholder="Added On Start Date"
                                            :teleport="true" :enable-time-picker="false" :start-time="startTime"></date-picker>
									</v-flex>
									<v-flex xs12 md3>
										<date-picker v-model="endDateSearch" placeholder="Added On End Date"
                                            :teleport="true" :enable-time-picker="false" ></date-picker>
									</v-flex>
								</v-layout>
							</v-container>
						</v-card-text>
						<v-divider></v-divider>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn text @click="clearFilter()">Clear All</v-btn>
							<v-btn color="primary" text @click="filterPanel=[]">Apply</v-btn>
						</v-card-actions>
					</v-card>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		<v-card v-if="selectedClients.length > 0">
			<v-card-text>
				<v-btn color="primary" @click="deleteSelected">Delete Selected</v-btn>
			</v-card-text>
		</v-card>
		<v-card>
			<v-card-text style="overflow-x: auto">
				<v-data-table
					dense
					v-model="selectedClients"
					show-select
					:mobile-breakpoint="mobileBreakpoint"
					:loading="loading"
					:headers="selectedColumns"
					:items="filteredClients"
					:footer-props="{
						'items-per-page-options': [10, 25, 50, 100, 500, { 'text': 'All', 'value': -1 }]
					}"
					:sort-by="['lastUpdated']"
					:sort-desc="[true]"
					:items-per-page="25"
					item-key="_id"
					must-sort
					class="elevation-1"
				>
					<template v-slot:[`item.serialNo`]="{ item }">
						<v-btn icon small class="mr-1" :to="{ name: 'Client', params: { id: item._id } }">
							<v-icon small>launch</v-icon>
						</v-btn>
						{{ item.serialNo}}
					</template>
					<template v-slot:[`item.phoneNumber`]="{ item }">{{ item.phoneNumber ? new PhoneNumber( item.phoneNumber.toString(), 'US' ).toJSON()['number']['national'] : 'unset' }}</template>
					<template v-slot:[`item.lastUpdated`]="{ item }">{{ moment(item.lastUpdated).format('M/D/YYYY h:mm a') }}</template>
					<template v-slot:[`item.isActive`]="{ item }"><v-icon>{{ item.isActive ? 'check_box' : 'check_box_outline_blank' }}</v-icon></template>
					<template v-slot:[`item.betaTester`]="{ item }"><v-icon>{{ item.betaTester ? 'check_box' : 'check_box_outline_blank' }}</v-icon></template>
					<template v-slot:[`item.emailPackage`]="{ item }"><v-icon>{{ item.emailPackage ? 'check_box' : 'check_box_outline_blank' }}</v-icon></template>
					<template v-slot:[`item.whatsApp`]="{ item }"><v-icon>{{ item.whatsApp ? 'check_box' : 'check_box_outline_blank' }}</v-icon></template>
					<template v-slot:[`item.sportsPackage`]="{ item }"><v-icon>{{ item.sportsPackage ? 'check_box' : 'check_box_outline_blank' }}</v-icon></template>
					<template v-slot:[`item.lastCheckIn`]="{ item }">{{ moment(item.lastCheckIn).calendar(null, { sameElse: 'M-D-YYYY h:mm:ss a' }) }}</template>
					<template v-slot:[`item.latestBuildDate`]="{ item }">{{ item.latestBuildDate ? moment(item.latestBuildDate).format('LL') : '' }}</template>
					<template v-slot:[`item.store`]="{ item }">{{ item.store && item.store.name ? item.store.name: '' }}</template>
					<template v-slot:[`item.auditor`]="{ item }">{{ item.auditor && item.auditor.name ? item.auditor.name: '' }}</template>
					<template v-slot:[`item.retailer`]="{ item }">{{ item.retailer && item.retailer.name ? item.retailer.name: '' }}</template>
					<template v-slot:[`item.autoEntitlement`]="{ item }"><v-icon>{{ item.autoEntitlement ? 'check_box' : 'check_box_outline_blank' }}</v-icon></template>
					<template v-slot:[`item.financeBan`]="{ item }"><v-icon>{{ item.financeBan ? 'check_box' : 'check_box_outline_blank' }}</v-icon></template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import PhoneNumber from 'awesome-phonenumber'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import JsonCsv from 'vue-json-csv'

export default {
	components: { DatePicker, JsonCsv},
	name: "ClientList",
	data () {
		return {
			loading: true,
			selectedClients: [],
			filterPanel: [],
			mobileBreakpoint: 0,
			moment: moment,
			serialSearch: '',
			nameSearch: '',
			emailSearch: '',
			phoneSearch: '',
			mccSearch: '',
			mncSearch: null,
			deviceSearch: '',
			retailerSearch: null,
			auditorSearch: null,
			storeSearch: null,
			imeiSearch: null,
			headers: [],
			selectedHeaders: ['serialNo', 'name', 'phoneNumber', 'retailer', 'store', 'auditor', 'lastUpdated', 'lastCheckIn', 'whatsApp'],
			clients: [],
			stores: [],
			retailers: [],
			auditors: [],
			startDateSearch: null,
            endDateSearch: null,
			startTime: { hours: 0, minutes: 0 }
		}
	},
	props: ['retailer', 'auditor'],
    computed: {
		filteredClients () {
			let clients = this.clients
			if (this.serialSearch) {
				clients = clients.filter(client => client.serialNo.includes(this.serialSearch.toUpperCase()))
			}
			if (this.nameSearch) {
				var term = this.nameSearch.toLowerCase()
				clients = clients.filter(client => client.name.toLowerCase().includes(term))
			}
			if (this.emailSearch) {
				clients = clients.filter(client => client.email ? client.email.toLowerCase().includes(this.emailSearch.toLowerCase()) : false)
			}
			if (this.phoneSearch) {
				clients = clients.filter(client => client.phoneNumber ? client.phoneNumber.toString().includes(this.phoneSearch) : false)
			}
			if (this.retailerSearch) {
				clients = clients.filter(client => client.retailer.name === this.retailerSearch)
			}
			if (this.auditorSearch) {
				clients = clients.filter(client => client.auditor ? client.auditor.name === this.auditorSearch  : false)
			}
			if (this.storeSearch) {
				clients = clients.filter(client => client.store.name === this.storeSearch)
			}
			if (this.mccSearch) {
				clients = clients.filter(client => client.mcc ? client.mcc.toString().includes(this.mccSearch) : false)
			}
			if (this.mncSearch) {
				clients = clients.filter(client => client.mnc ? client.mnc.toString().includes(this.mncSearch) : false)
			}
			if (this.deviceSearch) {
				clients = clients.filter(client => client.device ? client.device.includes(this.deviceSearch.toLowerCase()) : false)
			}
			if (this.imeiSearch) {
				clients = clients.filter(client => client.imei ? client.imei.toString().includes(this.imeiSearch) : false)
			}
			if (this.startDateSearch && this.endDateSearch) {
                clients = clients.filter(client => client.lastUpdated ? (client.lastUpdated >= this.startDateSearch && client.lastUpdated <= this.endDateSearch) : false)
            }
            else if (this.startDateSearch) {
                clients = clients.filter(client => client.lastUpdated ? client.lastUpdated >= this.startDateSearch : false)
            }
            else if (this.endDateSearch) {
                clients = clients.filter(client => client.lastUpdated ? client.lastUpdated <= this.endDateSearch : false)
            }
			return clients
		},
		PhoneNumber () {
			return PhoneNumber
		},
		selectedColumns() {
            return this.headers.filter(header => this.selectedHeaders.includes(header.value));
        },
		formattedDataForExport() {
            return this.filteredClients.map(client => ({
                ...client,
                retailerName: client.retailer ? client.retailer.name : '',
                retailerId: client.retailer ? client.retailer._id : '',
				storeName: client.store ? client.store.name : '',
                storeId: client.store ? client.store._id : '',
				auditorName: client.auditor ? client.auditor.name : '',
                auditorId: client.auditor ? client.auditor._id : '',
				accountNotes: client.accountNotes ? client.accountNotes: ''
            }))
        },
		fieldsForExport() {
			let fields = this.selectedHeaders.filter(header => header !== 'retailer' && header !== 'store' && header !=='auditor')
			fields.push('accountNotes')
			if(this.selectedHeaders.includes('retailer')) fields.push('retailerName', 'retailerId')
			if(this.selectedHeaders.includes('store')) fields.push('storeName', 'storeId')
			if(this.selectedHeaders.includes('auditor')) fields.push('auditorName', 'auditorId')
			return fields
        },
	},
	methods: {
		getClients () {
			this.loading = true
			axios.get(`https://api.safetelecom.net/api/clients?access=${this.retailer.accessLevel}`)
			.then(response => {
				this.clients = response.data
				this.loading = false
				this.extractHeaders();
			})
			axios.get(`https://api.safetelecom.net/api/stores?access=${this.retailer.accessLevel}`)
			.then(response => {
				this.stores = response.data
			})
			axios.get(`https://api.safetelecom.net/api/retailers`)
			.then(response => {
				this.retailers = response.data.sort((a, b) => {
					if(a.name < b.name) { return -1 }
					if(a.name > b.name) { return 1 }
					return 0
				})
			})
			axios.get(`https://api.safetelecom.net/api/auditors`)
			.then(response => {
				this.auditors = response.data.sort((a, b) => {
					if(a.name < b.name) { return -1 }
					if(a.name > b.name) { return 1 }
					return 0
				})
			})
		},
		extractHeaders() {
            if (this.clients.length > 0) {
                const allKeys = this.clients.reduce((keys, client) => {
                    keys = keys.concat(Object.keys(client));
                    return keys;
                }, []);

                const excludedHeaders = [
                    '_id',
					'__v',
					'baseband',
					'appNames',
					'activityBlocklist',
					'addedApps',
					'bannedApps',
					'extras',
					'removedApps',
					'excludedApps',
					'fcmTokens',
					'featureList',
					'adbEntitlement',
					'accountHash',
					'accountNotes',
					'includedApps'
                ];

                const unsortedHeaders = Array.from(new Set(allKeys))
                    .filter(key => !excludedHeaders.includes(key))
                    .map(key => {
						if(key === 'serialNo'){
							return{
								text: 'Serial Number',
								value: key,
								sortable: true
							}
						}
						else if(key === 'appStoreVersion'){
							return{
								text: 'Store Version',
								value: key,
								sortable: true
							}
						}
						else if(key === 'sdkVersion'){
							return{
								text: 'API',
								value: key,
								sortable: true
							}
						}
						else if(key === 'lastUpdated'){
							return{
								text: 'Added',
								value: key,
								sortable: true
							}
						}
						else if(key === 'betaTester'){
							return{
								text: 'Tester',
								value: key,
								sortable: true
							}
						}
						else if(key === 'lastCheckIn'){
							return{
								text: 'Checked In',
								value: key,
								sortable: true
							}
						}
						else{
							return{
								text: this.formatTitle(key),
								value: key,
								sortable: true
							}
						}
                    });

                //sort headers so that serialNo and Name are always the first
                let firstHeader = unsortedHeaders.filter(header => ['serialNo'].includes(header.value));
                let firstHeaders = firstHeader.concat(unsortedHeaders.filter(header => ['name'].includes(header.value)));
                let remainingHeaders = unsortedHeaders.filter(header => !['serialNo', 'name'].includes(header.value));
                this.headers = firstHeaders.concat(remainingHeaders);
            }
        },
		formatTitle(title) {
            const words = title.split(/(?=[A-Z])/);
            const formattedTitle = words.map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }).join(' ');
            return formattedTitle;
        },
		refreshPage () {
			this.clients = []
			this.getClients()
		},
		deleteSelected () {
			var selectedIds = this.selectedClients.map(a => a._id)
			var confirmDelete = confirm('Are you sure you want to delete?')
			if (confirmDelete == true) {
				selectedIds.forEach(id => {
					axios.delete(`https://api.safetelecom.net/api/clients/${id}`).then(() => {
					})
				})
				this.refreshPage()
			} else {
				alert("Canceled")
			}
		},
		clearFilter () {
			this.nameSearch = ''
			this.serialSearch = ''
			this.mccSearch = null
			this.mncSearch = null
			this.retailerSearch = ''
			this.auditorSearch = ''
			this.storeSearch = ''
			this.phoneSearch = null
			this.imeiSearch = null
			this.startDateSearch = null
            this.endDateSearch = null
		}
	},
	created () {
		this.getClients()
	}
}
</script>
