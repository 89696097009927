<template>
    <v-card>
        <v-list style="column-count: 2;">
            <v-list-item v-for="(value, featureName) in features" :key="featureName">
                <v-list-item-content>
                    <v-switch :label="featureName" :input-value="value"
                        @change="updateFeature(featureName, $event)"></v-switch>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
export default {
    name: 'ClientFeatureCodes',

    props: {
        features: {
            type: Object,
            default: () => ({})
        }
    },

    methods: {
        updateFeature(name, value) {
            this.$emit('update:feature', { name, value })
        }
    }
}
</script>