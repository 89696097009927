<template>
	<div>
		<v-toolbar dark color="secondary">
			<v-toolbar-title class="white--text">Add Multiple Clients</v-toolbar-title>
			<v-spacer></v-spacer>
			<input type="file" ref="fileInput" style="display: none" @change="handleFileUpload">
			<v-btn color="primary" @click="chooseFile">Choose File</v-btn>
		</v-toolbar>
		<v-form ref="form">
		<v-container fluid>
			<v-card>
				<v-card-title>
					Add Serial/Account Number
				</v-card-title>
				<v-card-text>
					<v-layout wrap>
						<v-flex xs12>
							<v-text-field outlined v-model="currentClient.serialNo" label="Account Number" required></v-text-field>
							<template v-slot:append-outer>
							</template>
						</v-flex>
						<v-flex xs12>
						<v-flex xs12>
							<v-text-field outlined v-model="currentClient.imei" label="IMEI"></v-text-field>
						</v-flex>
							<v-btn
								:color="loading ? 'normal' : 'primary'"
								:loading="loading"
								@click.native="submitItem()"
							>Submit</v-btn>
						</v-flex>
					</v-layout>
				</v-card-text>
			</v-card>
		</v-container>
		<v-container fluid>
			<v-card>
				<v-card-title>
					<span>Template</span>
					<v-spacer></v-spacer>
					<v-btn large color="primary" @click.native="refreshForm()">Reset</v-btn>
				</v-card-title>
				<v-card-text>
					<v-layout wrap>
						<v-flex xs12>
							<v-text-field
								outlined
								v-model="currentClient.name"
								label="Name"
								required
								:rules="[() => !!currentClient.name || 'This field is required']"
							></v-text-field>
						</v-flex>
						<v-flex xs12>
							<v-text-field outlined v-model="currentClient.email" label="Email"></v-text-field>
						</v-flex>
						<v-flex xs12 md6>
							<v-select
								v-model="currentClient.store"
								:items="stores"
								item-text="name"
								item-value="_id"
								label="Store"
								outlined
								required
							></v-select>
						</v-flex>
						<v-flex xs12 md6>
							<v-select
								v-model="currentClient.retailer"
								:items="retailers"
								item-text="name"
								item-value="_id"
								label="Retailer"
								outlined
								required
							></v-select>
						</v-flex>
						<v-flex xs12 md6>
							<v-select
								v-model="currentClient.auditor"
								:items="auditors"
								item-text="name"
								item-value="_id"
								label="Auditor"
								outlined
								required
							></v-select>
						</v-flex>
						<v-row justify="space-around">
							<v-flex xs12 md2>
								<v-switch label="Active Account" v-model="currentClient.isActive"></v-switch>
							</v-flex>
							<v-flex xs12 md2>
								<v-switch label="Include Email" v-model="currentClient.emailPackage"></v-switch>
							</v-flex>
							<v-flex xs12 md2>
								<v-switch label="Include WhatsApp" v-model="currentClient.whatsApp"></v-switch>
							</v-flex>
							<v-flex xs12 md2>
								<v-switch label="Beta Tester" v-model="currentClient.betaTester"></v-switch>
							</v-flex>
						</v-row>
					</v-layout>
				</v-card-text>
			</v-card>
		</v-container>
		</v-form>
		<MultiClientPopup :parsedData="parsedData" v-if="parsedData.length > 0" @clear="clearFileData"/>

	</div>
</template>
<script>
import axios from 'axios'
import MultiClientPopup from './MultiClientPopup.vue'

export default {
	components:{
		MultiClientPopup
	},
	data () {
		return {
			btnColor: 'primary',
			mobileBreakpoint: 0,
			loading: false,
			stores: [],
			retailers: [],
			auditors: [],
			currentClient: {
				name: '',
				email: '',
				serialNo: '',
				imei: '',
				emailPackage: false,
				isActive: true,
				betaTester: false,
				whatsApp: false,
				retailer: "5af7bd22246f50235ca7698d",
				auditor: "",
				store: "5a0d9be20823dc23dfcacc18"
			},
			parsedData: []
		}
	},
	methods: {
		getStores () {
			axios.get(`https://api.safetelecom.net/api/stores?access=${this.retailer?.accessLevel || ''}`)
			.then(response => {
				this.stores = response.data
			})
		},
		getRetailers () {
			axios.get(`https://api.safetelecom.net/api/retailers`)
			.then(response => {
				this.retailers = response.data.sort((a, b) => {
					if(a.name < b.name) { return -1 }
					if(a.name > b.name) { return 1 }
					return 0
				})
			})
		},
		getAuditors () {
			axios.get(`https://api.safetelecom.net/api/auditors`)
			.then(response => {
				this.auditors = response.data.sort((a, b) => {
					if(a.name < b.name) { return -1 }
					if(a.name > b.name) { return 1 }
					return 0
				})
			})
		},
		submitItem () {
			this.loading = true
			axios.post(`https://api.safetelecom.net/api/clients`, this.currentClient).then(response => {
				if(response.status === 200) {
					this.loading = false
				}
				else { alert('There has been an error with your request. Please contact us for support.') }
				this.currentClient.serialNo = ''
			})
		},
		refreshForm () {
			this.$refs.form.reset()

			// this.currentClient = {}
			this.getStores()
			this.getRetailers()
			this.getAuditors()
		},
		handleFileUpload(event){
			const file = event.target.files[0];
			if (!file) return;

			if(file.type !== 'text/csv'){
				alert('File must be CSV');
				event.target.value = '';
				return;
			}

			const reader = new FileReader();
			reader.onload = () =>{
				const csvData = reader.result
				console.log('string', csvData)

				const rows = csvData.split('\n')
				const csvObjects = []

				for(let i = 1; i < rows.length; i++){
					const values = rows[i].split(',')
					const obj = {
						serial: values[0]
					}
					if(values.length > 1){
						obj.imei = values[1]
					}
					csvObjects.push(obj)
				}
				this.parsedData = csvObjects
			}
			reader.readAsText(file);
		},
		chooseFile() {
			this.$refs.fileInput.click();
		},
		clearFileData(){
			this.parsedData = []
		}
	},
	created () {
		this.getStores()
		this.getRetailers()
		this.getAuditors()
	}
}
</script>
