<template>
<div>
		<v-toolbar dark color="secondary">
			<v-toolbar-title
				class="white--text"
			>Installed Apps</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-text-field
				v-model="searchTerm"
				hide-details
				prepend-icon="mdi-magnify"
				clearable
				label="Search"
				single-line
			></v-text-field>
			<v-btn
				icon
				@click="refreshPage"
			>
				<v-icon>mdi-refresh</v-icon>
			</v-btn>
		</v-toolbar>
		
		<v-card  tile >
			<v-card  tile>
				<v-data-table
					dense
					:search="searchTerm"
					:loading="dataLoading"
					:headers="headers"
					:items="apps"
					:footer-props="{
						'items-per-page-options': [25, 50, 100, 500, { 'text': 'All', 'value': -1 }]
					}"
					:sort-by="['installCount']"
					:sort-desc="[true]"
					:items-per-page="50"
					item-key="_id"
					must-sort
					class="elevation-1"
					show-expand
					:single-expand="singleExpand"
					:expanded.sync="expanded">
				
					<template v-slot:expanded-item="{ headers, item }">
						<td :colspan="headers.length">
							<v-card>
								<v-card-title>Clients</v-card-title>
								<v-card-text>
									<div class="sm-12 md-6"
										v-for="client in item.clients"
										:key="client"
									>
										{{ client.name }} // {{ client.serialNo}}
									</div>
								</v-card-text>
							</v-card>
						</td>
					</template>
					<template v-slot:[`item.installCount`]="{ item }">{{ item.installCount }}</template>
					<template v-slot:[`item.name`]="{ item }">{{ item.name }}</template>
					<template v-slot:[`item.realName`]="{ item }">{{ item.realName }}</template>
			</v-data-table>
			</v-card>
		</v-card>
	</div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
export default {
	name: "DataReports",
	data () {
		return {
			dataLoading: true,
			searchTerm: '',
			moment: moment,
			search: '',
			rows: [50, { 'text': 'All', 'value': -1 }],
			pagination: { 'sortBy': 'installCount', 'descending': true },
			headers: [
				{ text: 'Install Count', value: 'installCount' },
				{ text: 'App ID', value: 'name' },
				{ text: 'App Name', value: 'realName' },
			],
			expanded: [],
			singleExpand: true,
			apps: []
		}
	},
	methods: {
		getApps () {
			this.dataLoading = true
			axios.get(`https://api.safetelecom.net/reports/appinstalls`)
			.then(response => {
				this.apps = response.data
				this.dataLoading = false
			})
			
		},
		refreshPage () {
			this.clients = []
			this.getApps()
		}
	},
	created () {
		this.getApps()
	}
}
</script>