<template>
  <v-container fluid class="pa-0">
    <v-card>
      <v-app-bar dark color="primary">
        <v-btn icon @click="$router.push('/auditors')">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="isEditing"
          icon
          @click="deleteItem"
          class="mr-2"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn
          color="success"
          @click="saveItem"
          :loading="saving"
        >
          <v-icon left>mdi-content-save</v-icon>
          Save
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-form ref="form" v-model="isFormValid" @submit.prevent="saveItem">
          <v-tabs v-model="activeTab" background-color="transparent" grow>
            <v-tab>General Information</v-tab>
            <v-tab>Excluded Apps</v-tab>
            <v-tab>Global Settings</v-tab>
          </v-tabs>

          <v-tabs-items v-model="activeTab">
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model.trim="currentItem.name"
                        label="Name"
                        :rules="[v => !!v || 'Name is required']"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model.trim="currentItem.uid"
                        label="UID"
                        :rules="[v => !!v || 'UID is required']"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model.trim="currentItem.notes"
                        label="Notes"
                        outlined
                        auto-grow
                        rows="3"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model.trim="currentItem.role"
                        label="Role"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search apps"
                    single-line
                    hide-details
                    class="mb-4"
                  ></v-text-field>

                  <v-chip-group column class="mb-4">
                    <v-chip
                      v-for="packageName in currentItem.removedApps"
                      :key="packageName"
                      @click:close="removeApp(packageName)"
                      close
                      filter
                      outlined
                    >
                      {{ getAppName(packageName) }}
                    </v-chip>
                  </v-chip-group>

                  <v-data-table
                    :headers="appHeaders"
                    :items="filteredApps"
                    :search="search"
                    :loading="loading"
                    item-key="metadata.packageName"
                    :items-per-page="10"
                    class="elevation-1"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>
                          <v-checkbox
                            :input-value="isAppSelected(item.metadata.packageName)"
                            @change="toggleApp(item.metadata.packageName)"
                            hide-details
                          ></v-checkbox>
                        </td>
                        <td>{{ item.metadata.name }}</td>
                        <td>{{ item.metadata.packageName }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-switch
                    v-model="currentItem.globalSettings.disallowVideoPlayback"
                    label="Disallow Video Playback"
                    color="primary"
                  ></v-switch>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="3000"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AuditorDetail',
  
  data() {
    return {
      isFormValid: true,
      loading: true,
      saving: false,
      activeTab: 0,
      search: '',
      apps: [],
      appHeaders: [
        { text: 'App Name', value: 'metadata.name' },
        { text: 'Package Name', value: 'metadata.packageName' }
      ],
      currentItem: {
        timestamp: null,
        version: 1,
        name: '',
        uid: '',
        notes: '',
        role: '',
        removedApps: [],
        globalSettings: {
          disallowVideoPlayback: false
        }
      },
      snackbar: {
        show: false,
        text: '',
        color: ''
      }
    };
  },
  
  computed: {
    formTitle() {
      return this.isEditing ? 'Edit Auditor' : 'New Auditor';
    },
    isEditing() {
      return this.$route.params.auditor !== 'new';
    },
    filteredApps() {
      return this.apps.filter(app => 
        app.metadata.name.toLowerCase().includes(this.search.toLowerCase()) ||
        app.metadata.packageName.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  
  methods: {
    showSnackbar(text, color = 'success') {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },

    async fetchAuditor(id) {
      try {
        const response = await axios.get(`https://api.safetelecom.net/api/auditors/${id}`);
        this.currentItem = { 
          ...this.currentItem, 
          ...response.data,
          globalSettings: {
            ...this.currentItem.globalSettings,
            ...(response.data.globalSettings || {})
          }
        };
      } catch (error) {
        console.error('Error fetching auditor:', error);
        this.showSnackbar('Failed to fetch auditor details', 'error');
      }
    },
    
    async fetchApps() {
      try {
        const response = await axios.get('https://api.safetelecom.net/api/apps');
        this.apps = response.data.sort((a, b) => 
          a.metadata.name.localeCompare(b.metadata.name)
        );
      } catch (error) {
        console.error('Error fetching apps:', error);
        this.showSnackbar('Failed to fetch apps list', 'error');
      } finally {
        this.loading = false;
      }
    },
    
    async saveItem() {
      if (!this.$refs.form.validate()) return;

      this.saving = true;
      try {
        if (this.isEditing) {
          await axios.put(`https://api.safetelecom.net/api/auditors/${this.currentItem._id}`, this.currentItem);
        } else {
          await axios.post('https://api.safetelecom.net/api/auditors', this.currentItem);
        }
        this.showSnackbar('Auditor saved successfully');
        this.$router.push('/auditors');
      } catch (error) {
        console.error('Error saving auditor:', error);
        this.showSnackbar('Failed to save auditor', 'error');
      } finally {
        this.saving = false;
      }
    },
    
    async deleteItem() {
      if (!confirm('Are you sure you want to delete this item?')) return;

      try {
        await axios.delete(`https://api.safetelecom.net/api/auditors/${this.currentItem._id}`);
        this.showSnackbar('Auditor deleted successfully');
        this.$router.push('/auditors');
      } catch (error) {
        console.error('Error deleting auditor:', error);
        this.showSnackbar('Failed to delete auditor', 'error');
      }
    },

    isAppSelected(packageName) {
      return this.currentItem.removedApps.includes(packageName);
    },

    toggleApp(packageName) {
      const index = this.currentItem.removedApps.indexOf(packageName);
      if (index === -1) {
        this.currentItem.removedApps.push(packageName);
      } else {
        this.currentItem.removedApps.splice(index, 1);
      }
    },

    removeApp(packageName) {
      const index = this.currentItem.removedApps.indexOf(packageName);
      if (index !== -1) {
        this.currentItem.removedApps.splice(index, 1);
      }
    },

    getAppName(packageName) {
      const app = this.apps.find(app => app.metadata.packageName === packageName);
      return app ? app.metadata.name : packageName;
    }
  },
  
  async created() {
    if (this.isEditing) {
      await this.fetchAuditor(this.$route.params.auditor);
    }
    await this.fetchApps();
  },
};
</script>
