<template>
  <v-container fluid class="pa-0">
    <v-card>
      <v-app-bar dark color="primary">
        <v-toolbar-title>Auditors</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="refreshPage" :loading="loading">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn icon to="/auditors/new">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="items"
          :sort-by="['name']"
          :sort-desc="[false]"
          :items-per-page="-1"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, -1],
            'items-per-page-text': 'Rows per page'
          }"
          item-key="_id"
          must-sort
          class="elevation-1"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Search"
              class="mx-4"
              prepend-inner-icon="mdi-magnify"
              clearable
              single-line
              hide-details
            ></v-text-field>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.name }}</td>
              <td>{{ item.role }}</td>
              <td class="text-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      class="mr-2"
                      :to="'auditors/' + item._id"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Auditor</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      @click="openDeleteDialog(item)"
                      color="error"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Auditor</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- Confirmation Dialog -->
    <v-dialog v-model="deleteDialog.show" max-width="400">
      <v-card>
        <v-card-title class="headline">Confirm Delete</v-card-title>
        <v-card-text>
          Are you sure you want to delete the auditor "{{ deleteDialog.itemName }}"?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDeleteDialog">Cancel</v-btn>
          <v-btn color="error" text @click="confirmDelete">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="3000"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "AuditorsList",
  data() {
    return {
      loading: true,
      search: '',
      headers: [
        { text: "Name", value: "name" },
        { text: "Role", value: "role" },
        { text: "Actions", value: "actions", sortable: false, align: 'end', width: "120px" }
      ],
      items: [],
      snackbar: {
        show: false,
        text: '',
        color: ''
      },
      deleteDialog: {
        show: false,
        itemId: null,
        itemName: ''
      }
    };
  },
  methods: {
    showSnackbar(text, color = 'success') {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
    async getItems() {
      this.loading = true;
      try {
        const response = await axios.get(`https://api.safetelecom.net/api/auditors`);
        this.items = response.data;
        this.showSnackbar('Auditors loaded successfully');
      } catch (error) {
        console.error('Error fetching auditors:', error);
        this.showSnackbar('Failed to load auditors', 'error');
      } finally {
        this.loading = false;
      }
    },
    openDeleteDialog(item) {
      this.deleteDialog.itemId = item._id;
      this.deleteDialog.itemName = item.name;
      this.deleteDialog.show = true;
    },
    closeDeleteDialog() {
      this.deleteDialog.show = false;
      this.deleteDialog.itemId = null;
      this.deleteDialog.itemName = '';
    },
    async confirmDelete() {
      try {
        await axios.delete(`https://api.safetelecom.net/api/auditors/${this.deleteDialog.itemId}`);
        this.showSnackbar('Auditor deleted successfully');
        await this.refreshPage();
      } catch (error) {
        console.error('Error deleting auditor:', error);
        this.showSnackbar('Failed to delete auditor', 'error');
      } finally {
        this.closeDeleteDialog();
      }
    },
    async refreshPage() {
      this.items = [];
      await this.getItems();
    },
  },
  created() {
    this.getItems();
  },
};
</script>