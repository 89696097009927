<template>
  <v-container fluid>
    <v-row>
      <v-toolbar dark color="secondary">
        <v-btn rounded dark text class="mr-2" to="/orders">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title class="white--text">Shopify Line Item Mappings</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon rounded dark @click="refreshMappings" :loading="loading">
          <v-icon>refresh</v-icon>
        </v-btn>
      </v-toolbar>
    </v-row>

    <v-row v-if="loading">
      <v-col class="text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <v-alert type="info" class="mb-4">
          <v-icon left>mdi-information-outline</v-icon>
          <span class="font-weight-bold">Info:</span> Configure how Shopify product names are mapped to App Store packages, auditors, and features.
          Pattern matching is case-insensitive and uses substring matching. Mappings are applied automatically when an order is selected.
        </v-alert>
        
        <v-card>
          <v-card-title>
            <v-icon left>mdi-shopping</v-icon>
            Map Shopify Line Items to SafeTelecom Products
          </v-card-title>
          <v-card-text>
            <!-- Packages/Stores Section -->
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <v-icon left color="primary">mdi-store</v-icon>
                    Map Line Items to Stores
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-btn color="primary" text class="mb-3" @click="addMappingItem('packages')">
                    <v-icon left>mdi-plus</v-icon>Add Store Mapping
                  </v-btn>
                  
                  <v-card outlined class="mb-3" v-for="(mapping, index) in mappings.packages" :key="'pkg-'+index">
                    <v-card-title class="subtitle-1">
                      Store Mapping #{{ index + 1 }}
                      <v-spacer></v-spacer>
                      <v-btn icon small @click="removeMappingItem('packages', index)">
                        <v-icon small>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-select
                        v-model="mapping.id"
                        :items="stores"
                        item-text="name"
                        item-value="_id"
                        label="Store"
                        outlined
                        required
                      ></v-select>
                      
                      <v-combobox
                        v-model="mapping.patterns"
                        label="Pattern Matches (hit Enter to add)"
                        outlined
                        chips
                        multiple
                        hint="Patterns that will trigger this store mapping"
                        persistent-hint
                      ></v-combobox>
                    </v-card-text>
                  </v-card>
                  
                  <v-alert
                    v-if="mappings.packages.length === 0"
                    type="info"
                    outlined
                  >
                    No store mappings defined. Click "Add Store Mapping" to create one.
                  </v-alert>
                </v-expansion-panel-content>
              </v-expansion-panel>
              
              <!-- Auditors Section -->
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <v-icon left color="primary">mdi-shield-account</v-icon>
                    Map Line Items to Auditors
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-btn color="primary" text class="mb-3" @click="addMappingItem('auditors')">
                    <v-icon left>mdi-plus</v-icon>Add Auditor Mapping
                  </v-btn>
                  
                  <v-card outlined class="mb-3" v-for="(mapping, index) in mappings.auditors" :key="'aud-'+index">
                    <v-card-title class="subtitle-1">
                      Auditor Mapping #{{ index + 1 }}
                      <v-spacer></v-spacer>
                      <v-btn icon small @click="removeMappingItem('auditors', index)">
                        <v-icon small>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-select
                        v-model="mapping.id"
                        :items="auditors"
                        item-text="name"
                        item-value="_id"
                        label="Auditor"
                        outlined
                        required
                      ></v-select>
                      
                      <v-combobox
                        v-model="mapping.patterns"
                        label="Pattern Matches (hit Enter to add)"
                        outlined
                        chips
                        multiple
                        hint="Patterns that will trigger this auditor mapping"
                        persistent-hint
                      ></v-combobox>
                    </v-card-text>
                  </v-card>
                  
                  <v-alert
                    v-if="mappings.auditors.length === 0"
                    type="info"
                    outlined
                  >
                    No auditor mappings defined. Click "Add Auditor Mapping" to create one.
                  </v-alert>
                </v-expansion-panel-content>
              </v-expansion-panel>
              
              <!-- Features Section -->
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <v-icon left color="primary">mdi-puzzle</v-icon>
                    Map Line Items to Features
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-btn color="primary" text class="mb-3" @click="addMappingItem('features')">
                    <v-icon left>mdi-plus</v-icon>Add Feature Mapping
                  </v-btn>
                  
                  <v-card outlined class="mb-3" v-for="(mapping, index) in mappings.features" :key="'feat-'+index">
                    <v-card-title class="subtitle-1">
                      Feature Mapping #{{ index + 1 }}
                      <v-spacer></v-spacer>
                      <v-btn icon small @click="removeMappingItem('features', index)">
                        <v-icon small>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-select
                        v-model="mapping.key"
                        :items="featureOptions"
                        item-text="name"
                        item-value="key"
                        label="Feature"
                        outlined
                        required
                      ></v-select>
                      
                      <v-switch
                        v-model="mapping.value"
                        :label="`Value: ${mapping.value ? 'True' : 'False'}`"
                        hint="Set to true to enable this feature when a pattern matches"
                        persistent-hint
                      ></v-switch>
                      
                      <v-combobox
                        v-model="mapping.patterns"
                        label="Pattern Matches (hit Enter to add)"
                        outlined
                        chips
                        multiple
                        hint="Patterns that will trigger this feature setting"
                        persistent-hint
                      ></v-combobox>
                    </v-card-text>
                  </v-card>
                  
                  <v-alert
                    v-if="mappings.features.length === 0"
                    type="info"
                    outlined
                  >
                    No feature mappings defined. Click "Add Feature Mapping" to create one.
                  </v-alert>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            
            <div class="mt-4">
              <v-alert v-if="saveError" type="error" dismissible>
                {{ saveError }}
              </v-alert>
              
              <v-alert v-if="saveSuccess" type="success" dismissible>
                Mappings saved successfully.
              </v-alert>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text to="/orders">Cancel</v-btn>
            <v-btn color="primary" @click="saveMappings" :loading="saving" :disabled="saving">
              Save Mappings
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    
    <!-- Test mapping panel -->
    <v-row class="mt-4">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-icon left>mdi-test-tube</v-icon>
            Test Mapping
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="testLineItem"
              label="Enter a line item title (and optional variant with format 'Title - Variant')"
              outlined
              @keyup.enter="testMapping"
              hint="Example: 'Product Name - Variant'"
              persistent-hint
            ></v-text-field>
            
            <v-btn color="info" @click="testMapping" :disabled="!testLineItem.trim()" class="mb-4">
              Test Mapping
            </v-btn>
            
            <div v-if="testResults">
              <v-divider class="my-4"></v-divider>
              <h3 class="subtitle-1">Test Results:</h3>
              
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Mapping</th>
                      <th>Matched Pattern</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="testResults.store">
                      <td>Store</td>
                      <td>{{ getStoreName(testResults.store) }}</td>
                      <td>{{ testResults.storePattern }}</td>
                    </tr>
                    <tr v-else>
                      <td>Store</td>
                      <td colspan="2" class="text-center">No match</td>
                    </tr>
                    
                    <tr v-if="testResults.auditor">
                      <td>Auditor</td>
                      <td>{{ getAuditorName(testResults.auditor) }}</td>
                      <td>{{ testResults.auditorPattern }}</td>
                    </tr>
                    <tr v-else>
                      <td>Auditor</td>
                      <td colspan="2" class="text-center">No match</td>
                    </tr>
                    
                    <template v-for="(value, key) in testResults.features">
                      <tr :key="key">
                        <td>Feature: {{ getFeatureName(key) }}</td>
                        <td>{{ value ? 'Enabled' : 'Disabled' }}</td>
                        <td>{{ testResults.featurePatterns[key] }}</td>
                      </tr>
                    </template>
                    <tr v-if="Object.keys(testResults.features).length === 0">
                      <td>Features</td>
                      <td colspan="2" class="text-center">No matches</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { clientApi } from '../services/api'

export default {
  name: 'OrderMappings',
  data() {
    return {
      loading: false,
      saving: false,
      saveError: null,
      saveSuccess: false,
      stores: [],
      auditors: [],
      mappings: {
        packages: [],
        auditors: [],
        features: []
      },
      testLineItem: '',
      testResults: null,
      featureOptions: [
        { key: 'emailPackage', name: 'Email Package' },
        { key: 'whatsApp', name: 'WhatsApp' },
        { key: 'sportsPackage', name: 'Sports Package' },
        { key: 'betaTester', name: 'Beta Tester' }
      ]
    }
  },
  created() {
    // Initialize with empty mappings structure
    this.mappings = {
      packages: [],
      auditors: [],
      features: []
    };
    
    // Then load mappings from API
    this.$nextTick(() => {
      this.getMappings();
    });
  },
  methods: {
    async getMappings() {
      this.loading = true
      try {
        // Use mock data for development if requested, otherwise use live API
        let mappingsPromise;
        if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_USE_MOCK_DATA === 'true') {
          mappingsPromise = clientApi.getMockOrderMappings ? clientApi.getMockOrderMappings() : clientApi.getOrderMappings();
        } else {
          mappingsPromise = clientApi.getOrderMappings();
        }
        
        const [mappingsResponse, storesResponse, auditorsResponse] = await Promise.all([
          mappingsPromise,
          clientApi.getStores(this.retailer?.accessLevel),
          clientApi.getAuditors()
        ])
        
        this.stores = storesResponse.data || []
        this.auditors = auditorsResponse.data || []
        
        // Check for presence of common entries - no need to store in variables
        this.stores.find(store => store.name === "SafeTelecom Basic")
        this.auditors.find(auditor => auditor.name === "None")
        
        if (mappingsResponse.data) {
          // If data is nested under a data property
          // Handle different response structures
          let data;
          if (mappingsResponse.data && typeof mappingsResponse.data === 'object') {
            if (mappingsResponse.data.data) {
              // Case 1: { data: { data: {...} } }
              data = mappingsResponse.data.data;
            } else {
              // Case 2: { data: {...} }
              data = mappingsResponse.data;
            }
          } else {
            // Case 3: Response is not as expected
            data = {};
          }
          
          // Handle different property naming between backend and frontend
          // Backend uses: store, auditor, feature
          // Frontend uses: packages, auditors, features
          
          // Map the properties with proper field mapping
          let packages = [];
          let auditors = [];
          let features = [];
          
          // Handle stores/packages
          if (data && data.store && Array.isArray(data.store)) {
            packages = data.store.map(item => {
              return {
                id: item.targetId,
                patterns: Array.isArray(item.patterns) ? [...item.patterns] : [],
                _id: item._id // preserve the original ID if present
              };
            });
          }
          
          // Handle auditors
          if (data && data.auditor && Array.isArray(data.auditor)) {
            auditors = data.auditor.map(item => {
              return {
                id: item.targetId,
                patterns: Array.isArray(item.patterns) ? [...item.patterns] : [],
                _id: item._id // preserve the original ID if present
              };
            });
          }
          
          // Handle features
          if (data && data.feature && Array.isArray(data.feature)) {
            features = data.feature.map(item => {
              return {
                key: item.targetValue,
                value: true, // Default to true since the backend doesn't seem to store this
                patterns: Array.isArray(item.patterns) ? [...item.patterns] : [],
                _id: item._id // preserve the original ID if present
              };
            });
          }
          
          this.mappings = {
            packages,
            auditors,
            features
          }
          
          // Ensure patterns is an array for each mapping
          this.mappings.packages.forEach(pkg => {
            if (!Array.isArray(pkg.patterns)) {
              pkg.patterns = []
            }
          })
          
          this.mappings.auditors.forEach(aud => {
            if (!Array.isArray(aud.patterns)) {
              aud.patterns = []
            }
          })
          
          this.mappings.features.forEach(feat => {
            if (!Array.isArray(feat.patterns)) {
              feat.patterns = []
            }
          })
        } else {
          // Initialize with empty arrays as fallback
          this.mappings = {
            packages: [],
            auditors: [],
            features: []
          }
        }
      } catch (error) {
        this.$root.$emit('show-snackbar', {
          color: 'error',
          message: 'Failed to load mappings: ' + (error.response?.data?.message || error.message)
        })
        
        // Initialize with empty arrays as fallback on error
        this.mappings = {
          packages: [],
          auditors: [],
          features: []
        }
        
        // No default mappings will be added on error
      } finally {
        this.loading = false
      }
    },
    
    // These methods have been disabled as we don't want default mappings
    addDefaultPackageMappings() {
      // No default mappings will be added
      return;
    },
    
    addDefaultAuditorMappings() {
      // No default mappings will be added
      return;
    },
    
    addDefaultFeatureMappings() {
      // No default mappings will be added
      return;
    },
    
    async saveMappings() {
      this.saving = true
      this.saveError = null
      this.saveSuccess = false
      
      try {
        // Validate that each mapping has an ID and at least one pattern
        const validPackages = this.mappings.packages.filter(p => p.id && Array.isArray(p.patterns) && p.patterns.length > 0)
        const validAuditors = this.mappings.auditors.filter(a => a.id && Array.isArray(a.patterns) && a.patterns.length > 0)
        const validFeatures = this.mappings.features.filter(f => f.key && Array.isArray(f.patterns) && f.patterns.length > 0)
        
        // The backend has a confusing structure:
        // 1. Outer properties are "packages", "auditors", "features"
        // 2. Inner properties for each item are "targetId"/"targetValue"
        // 3. But in the database, it's stored as "store", "auditor", "feature"
        
        // Transform for the outer "packages" property but inner items use "targetId"
        const transformedPackages = validPackages.map(p => {
          const result = {
            targetId: p.id, // Must be "targetId" according to error message
            patterns: [...p.patterns]
          };
          
          // Only include _id if it exists
          if (p._id) {
            result._id = p._id;
          }
          
          return result;
        });
        
        const transformedAuditors = validAuditors.map(a => {
          const result = {
            targetId: a.id, // Must be "targetId"
            patterns: [...a.patterns]
          };
          
          // Only include _id if it exists
          if (a._id) {
            result._id = a._id;
          }
          
          return result;
        });
        
        const transformedFeatures = validFeatures.map(f => {
          const result = {
            targetValue: f.key, // Must be "targetValue"
            patterns: [...f.patterns]
          };
          
          // Only include _id if it exists
          if (f._id) {
            result._id = f._id;
          }
          
          return result;
        });
        
        // Outer property names must be "packages", "auditors", "features"
        const payload = {
          packages: transformedPackages,
          auditors: transformedAuditors,
          features: transformedFeatures
        }
        
        const savedResponse = await clientApi.saveOrderMappings(payload)
        this.saveSuccess = true
        
        // Handle the response and update local mappings
        if (savedResponse && savedResponse.data) {
          // The backend might return the updated data with new IDs
          // Refresh to get the updated mappings with proper IDs
          await this.refreshMappings();
        } else {
          // If no data returned, just update local state with what we have
          this.mappings = {
            packages: JSON.parse(JSON.stringify(validPackages)),
            auditors: JSON.parse(JSON.stringify(validAuditors)),
            features: JSON.parse(JSON.stringify(validFeatures))
          }
        }
        
        this.$root.$emit('show-snackbar', {
          color: 'success',
          message: 'Mappings saved successfully'
        })
      } catch (error) {
        this.saveError = error.response?.data?.message || error.message
        this.$root.$emit('show-snackbar', {
          color: 'error',
          message: 'Failed to save mappings: ' + this.saveError
        })
      } finally {
        this.saving = false
      }
    },
    
    addMappingItem(type) {
      if (type === 'packages') {
        // Find "SafeTelecom Basic" store or fall back to first store
        const basicStore = this.stores.find(store => store.name === "SafeTelecom Basic")
        const defaultStoreId = basicStore ? basicStore._id : (this.stores.length ? this.stores[0]._id : '')
        
        this.mappings.packages.push({
          id: defaultStoreId,
          patterns: []
          // No _id for new items - the backend will assign one
        })
      } else if (type === 'auditors') {
        // Find "None" auditor or fall back to first auditor
        const noneAuditor = this.auditors.find(auditor => auditor.name === "None")
        const defaultAuditorId = noneAuditor ? noneAuditor._id : (this.auditors.length ? this.auditors[0]._id : '')
        
        this.mappings.auditors.push({
          id: defaultAuditorId,
          patterns: []
          // No _id for new items - the backend will assign one
        })
      } else if (type === 'features') {
        this.mappings.features.push({
          key: 'emailPackage',
          value: true,
          patterns: []
          // No _id for new items - the backend will assign one
        })
      }
    },
    
    removeMappingItem(type, index) {
      if (confirm('Are you sure you want to remove this mapping?')) {
        this.mappings[type].splice(index, 1)
      }
    },
    
    testMapping() {
      if (!this.testLineItem.trim()) return
      
      // Extract variant information if present (format: "Title - Variant")
      let title = this.testLineItem
      let variant = ''
      
      if (this.testLineItem.includes(' - ')) {
        const parts = this.testLineItem.split(' - ')
        title = parts[0]
        variant = parts.slice(1).join(' - ')
      }
      
      const lineItem = title.toLowerCase()
      const variantLower = variant.toLowerCase()
      const results = {
        store: null,
        storePattern: null,
        auditor: null,
        auditorPattern: null,
        features: {},
        featurePatterns: {}
      }
      
      // Test store mappings
      for (const pkg of this.mappings.packages) {
        for (const pattern of pkg.patterns) {
          const patternLower = pattern.toLowerCase()
          if (lineItem.includes(patternLower) || variantLower.includes(patternLower)) {
            results.store = pkg.id
            results.storePattern = pattern
            break
          }
        }
        if (results.store) break
      }
      
      // Test auditor mappings
      for (const aud of this.mappings.auditors) {
        for (const pattern of aud.patterns) {
          const patternLower = pattern.toLowerCase()
          if (lineItem.includes(patternLower) || variantLower.includes(patternLower)) {
            results.auditor = aud.id
            results.auditorPattern = pattern
            break
          }
        }
        if (results.auditor) break
      }
      
      // Test feature mappings
      for (const feat of this.mappings.features) {
        for (const pattern of feat.patterns) {
          const patternLower = pattern.toLowerCase()
          if (lineItem.includes(patternLower) || variantLower.includes(patternLower)) {
            results.features[feat.key] = feat.value
            results.featurePatterns[feat.key] = pattern
            break
          }
        }
      }
      
      this.testResults = results
    },
    
    refreshMappings() {
      // Reset mapping state before fetching
      this.mappings = {
        packages: [],
        auditors: [],
        features: []
      };
      
      this.getMappings();
    },
    
    getStoreName(id) {
      const store = this.stores.find(s => s._id === id)
      return store ? store.name : id
    },
    
    getAuditorName(id) {
      const auditor = this.auditors.find(a => a._id === id)
      return auditor ? auditor.name : id
    },
    
    getFeatureName(key) {
      const feature = this.featureOptions.find(f => f.key === key)
      return feature ? feature.name : key
    }
  }
}
</script>

<style scoped>
.v-card__title {
  word-break: normal;
}
</style>