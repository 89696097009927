<template>
	<div>
		<v-container fluid>
			<v-row>
				<v-toolbar dark color="secondary">
					<v-toolbar-title class="white--text">Orders</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn icon rounded dark @click="syncOrders" class="mr-2" title="Sync latest unfulfilled orders">
						<v-icon>mdi-cloud-sync</v-icon>
					</v-btn>
					<v-btn icon rounded dark @click="refreshPage">
						<v-icon>refresh</v-icon>
					</v-btn>
				</v-toolbar>
			</v-row>
			<v-row v-if="loading">
				<v-col class="text-center">
					<v-progress-circular indeterminate color="primary"></v-progress-circular>
				</v-col>
			</v-row>
			<v-row>
				<v-col md="4">
					<v-card class="mb-4">
						<v-card-title>
							<v-icon left>mdi-cart</v-icon>
							Shippable Unfulfilled Orders
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-icon small class="ml-1" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
								</template>
								<span>Only showing orders with at least one item that requires shipping</span>
							</v-tooltip>
							<v-spacer></v-spacer>
							<v-text-field
								v-model="search"
								append-icon="mdi-magnify"
								label="Search"
								single-line
								hide-details
								dense
								outlined
								class="ml-2"
							></v-text-field>
						</v-card-title>
						<v-divider></v-divider>
						<v-list two-line>
							<v-list-item-group v-model="selectedOrder" active-class="blue--text">
								<template v-if="filteredOrders.length === 0">
									<v-list-item>
										<v-list-item-content>
											<v-list-item-title class="text-center">No unfulfilled orders found</v-list-item-title>
											<v-list-item-subtitle class="text-center">Click the sync button to check for new orders</v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>
								</template>
								<v-list-item v-for="order in filteredOrders" :key="order.id">
									<v-list-item-icon>
										<v-badge :content="order.line_items.length" :value="order.line_items.length" color="primary" overlap>
											<v-icon>mdi-package-variant-closed</v-icon>
										</v-badge>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>
											#{{ order.name }} - {{ order.customer?.first_name }} {{ order.customer?.last_name }}
										</v-list-item-title>
										<v-list-item-subtitle class="text--primary customer-info">
											{{ order.customer?.email }} 
											<template v-if="order.customer?.phone">&bullet; {{ formatPhoneNumber(order.customer.phone) }}</template>
										</v-list-item-subtitle>
										<v-list-item-subtitle 
											v-for="(item, itemIndex) in order.line_items" 
											:key="itemIndex" 
											class="line-item-subtitle"
											:class="{'line-item-primary': itemIndex === 0, 'line-item-secondary': itemIndex > 0}"
										>
											<v-chip x-small label outlined :color="itemIndex === 0 ? 'primary' : 'secondary'" class="mr-1">
												{{ item.quantity }}
											</v-chip>
											<v-chip x-small label v-if="item.requires_shipping" color="success" class="mr-1">
												<v-icon x-small left>mdi-package-variant</v-icon>
												Ship
											</v-chip>
											{{ item.title }} {{ item.variant_title ? `- ${item.variant_title}` : '' }}
										</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action>
										<v-list-item-action-text>{{ formatDate(order.created_at) }}</v-list-item-action-text>
									</v-list-item-action>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</v-card>
				</v-col>
				<v-col md="8">
					<v-card>
						<v-card-title>
							<v-icon left>mdi-account-plus</v-icon>
							Client Account Setup
							<v-spacer></v-spacer>
							<v-btn
								text
								small
								color="primary"
								to="/order-mappings"
								title="Configure order to field mappings"
							>
								<v-icon left>mdi-cog</v-icon>
								<span>Mappings</span>
								<v-badge
									color="warning"
									content="New!"
									inline
									class="ml-1"
								></v-badge>
							</v-btn>
						</v-card-title>
						<v-form ref="form">
							<v-card-text>
								<v-layout wrap>
									<v-flex xs12>
										<v-text-field 
											outlined 
											v-model="currentOrder.name" 
											label="Name" 
											required
											:rules="[() => !!currentOrder.name || 'This field is required']"
										></v-text-field>
									</v-flex>
									<v-flex xs12>
										<v-text-field 
											outlined 
											v-model="currentOrder.email" 
											label="Email"
											:rules="emailRules"
										></v-text-field>
									</v-flex>
									<v-flex xs12>
										<v-text-field 
											outlined 
											v-model="currentOrder.serialNo" 
											label="Account Number" 
											ref="accountNumber" 
											v-on:keyup.enter="focusOnInputField('imei')"
											:rules="[() => !!currentOrder.serialNo || 'Account number is required']"
										></v-text-field>
									</v-flex>
									<v-flex xs12>
										<v-text-field 
											outlined 
											v-model="currentOrder.imei" 
											label="IMEI" 
											ref="imei"
											:rules="imeiRules"
										></v-text-field>
									</v-flex>
									<v-flex xs12 md6>
										<v-select 
											v-model="currentOrder.store" 
											:items="stores" 
											item-text="name" 
											item-value="_id" 
											label="Store"
											outlined 
											required
											:rules="[() => !!currentOrder.store || 'Please select a store']"
										></v-select>
									</v-flex>
									<!-- Retailer is automatically set to current user's retailer -->
									<v-flex xs12 md6>
										<v-select 
											v-model="currentOrder.auditor" 
											:items="auditors" 
											item-text="name" 
											item-value="_id" 
											label="Auditor"
											outlined 
											required
											:rules="[() => !!currentOrder.auditor || 'Please select an auditor']"
										></v-select>
									</v-flex>
									<v-row justify="space-around">
										<v-flex xs12 md3>
											<v-switch label="Active Account" v-model="currentOrder.isActive"></v-switch>
										</v-flex>
										<v-flex xs12 md3>
											<v-switch label="Include Email" v-model="currentOrder.emailPackage"></v-switch>
										</v-flex>
										<v-flex xs12 md3>
											<v-switch label="Include WhatsApp" v-model="currentOrder.whatsApp"></v-switch>
										</v-flex>
										<v-flex xs12 md3>
											<v-switch label="Beta Tester" v-model="currentOrder.betaTester"></v-switch>
										</v-flex>
									</v-row>
								</v-layout>
							</v-card-text>
						</v-form>
						<v-divider></v-divider>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn text @click="refreshPage()">Cancel</v-btn>
							<v-btn 
								color="primary" 
								@click="submitForm" 
								:loading="submitting" 
								:disabled="!selectedOrderData || submitting"
							>
								Create Client Record
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
		
		<!-- Dialog with Shopify Order Link -->
		<v-dialog v-model="showShopifyDialog" max-width="500px">
			<v-card>
				<v-card-title class="headline">
					<v-icon left class="mr-2">mdi-check-circle</v-icon>
					Client Record Created
				</v-card-title>
				<v-card-text>
					<p>The client record has been successfully created. To complete the order fulfillment and create a shipping label, you will need to finish the process in Shopify.</p>
					<div class="mt-4">
						<v-chip class="mb-2 mr-2" color="primary" outlined>
							<v-icon left>mdi-account</v-icon>
							Client ID: {{ createdClientId }}
						</v-chip>
						<v-chip class="mb-2" color="primary" outlined>
							<v-icon left>mdi-cart</v-icon>
							Order #{{ shopifyOrderName }}
						</v-chip>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-btn text @click="refreshPage">
						Return to Orders
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn text color="primary" :href="`/clients/${createdClientId}`" target="_blank">
						<v-icon left>mdi-account</v-icon>
						View Client
					</v-btn>
					<v-btn color="primary" :href="getShopifyOrderUrl()" target="_blank">
						<v-icon left>mdi-cart</v-icon>
						Open in Shopify
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import moment from 'moment'
import PhoneNumber from 'awesome-phonenumber'
import { clientApi } from '../services/api'

export default {
	name: "OrdersRoute",
	props: ['retailer'],
	data () {
		return {
			selectedOrder: null,
			search: '',
			orders: [],
			stores: [],
			retailers: [],
			auditors: [],
			loading: false,
			submitting: false,
			showShopifyDialog: false,
			createdClientId: null,
			shopifyOrderId: null,
			shopifyOrderName: null,
			emailRules: [
				v => !v || /.+@.+\..+/.test(v) || 'Email must be valid'
			],
			imeiRules: [
				v => !v || (v.length >= 15 && /^\d+$/.test(v)) || 'IMEI must be at least 15 digits'
			]
		}
	},
	computed: {
		filteredOrders() {
			if (!this.search) {
				return this.orders
			}
			const searchLower = this.search.toLowerCase()
			return this.orders.filter(order => {
				const customerName = `${order.customer?.first_name || ''} ${order.customer?.last_name || ''}`.toLowerCase()
				const orderNumber = order.name?.toLowerCase() || ''
				const email = order.customer?.email?.toLowerCase() || ''
				
				return customerName.includes(searchLower) || 
					orderNumber.includes(searchLower) || 
					email.includes(searchLower) ||
					order.line_items.some(item => item.title.toLowerCase().includes(searchLower))
			})
		},
		selectedOrderData() {
			return this.selectedOrder !== null && this.filteredOrders.length > 0 
				? this.filteredOrders[this.selectedOrder] 
				: null
		},
		currentOrder() {
			if (!this.selectedOrderData) {
				return {
					name: '',
					email: '',
					serialNo: '',
					imei: '',
					emailPackage: false,
					isActive: true,
					betaTester: false,
					whatsApp: false,
					sportsPackage: false,
					activityBlocklist: [],
					retailer: this.defaultRetailer || "",
					auditor: this.defaultAuditor || "",
					store: this.defaultStore || "",
					featureList: {},
					shopifyOrderId: null
				}
			}
			
			const order = this.selectedOrderData
			const customerName = order.customer ? 
				`${order.customer.first_name || ''} ${order.customer.last_name || ''}`.trim() : 
				''
			
			// Start with defaults
			const clientData = {
				name: customerName,
				email: order.customer?.email || '',
				serialNo: '',
				imei: '',
				emailPackage: this.detectEmailPackage(order),
				isActive: true,
				betaTester: false,
				whatsApp: this.detectWhatsAppPackage(order),
				sportsPackage: this.detectSportsPackage(order),
				activityBlocklist: [],
				retailer: this.defaultRetailer || "",  // Retailer is always from the current user
				auditor: this.defaultAuditor || "",
				store: this.defaultStore || "", 
				featureList: {},
				shopifyOrderId: order.id
			}
			
			// Apply any automatic mappings if they exist
			if (order.mappedValues) {
				// Override store if mapped
				if (order.mappedValues.store) {
					clientData.store = order.mappedValues.store
				}
				
				// Override auditor if mapped
				if (order.mappedValues.auditor) {
					clientData.auditor = order.mappedValues.auditor
				}
				
				// Override feature flags if mapped
				if (order.mappedValues.features) {
					Object.assign(clientData, order.mappedValues.features)
				}
			}
			
			return clientData
		},
		defaultStore() {
			// Find "SafeTelecom Basic" store or fall back to first store
			const basicStore = this.stores.find(store => store.name === "SafeTelecom Basic")
			return basicStore ? basicStore._id : (this.stores.length > 0 ? this.stores[0]._id : "")
		},
		defaultRetailer() {
			return this.retailers.length > 0 ? this.retailers[0]._id : ""
		},
		defaultAuditor() {
			// Find "None" auditor or fall back to first auditor
			const noneAuditor = this.auditors.find(auditor => auditor.name === "None")
			return noneAuditor ? noneAuditor._id : (this.auditors.length > 0 ? this.auditors[0]._id : "")
		}
	},
	methods: {
		formatPhoneNumber(phoneNumber) {
			if (!phoneNumber) return ''
			try {
				return new PhoneNumber(phoneNumber.toString(), 'US').toJSON()['number']['national']
			} catch (e) {
				return phoneNumber
			}
		},
		formatDate(date) {
			return moment(date).format('L')
		},
		focusOnInputField(ref) {
			if (this.$refs[ref]) {
				this.$refs[ref].focus()
			}
		},
		async getData() {
			try {
				this.loading = true
				this.selectedOrder = null
				
				// Test connection to Shopify API proxy first
				try {
					await clientApi.testShopifyConnection()
				} catch (connError) {
					// Continue with other data loading even if connection test fails
				}
				
				// Use mock data for development if requested, otherwise use live API
				let ordersPromise
				if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_USE_MOCK_DATA === 'true') {
					ordersPromise = clientApi.getMockUnfulfilledOrders()
				} else {
					// Try to get orders with fallback if unfulfilled is empty
					ordersPromise = clientApi.getOrdersWithFallback()
				}
				
				const [ordersResponse, storesResponse, retailersResponse, auditorsResponse] = await Promise.all([
					ordersPromise,
					clientApi.getStores(this.retailer?.accessLevel),
					clientApi.getRetailers(),
					clientApi.getAuditors()
				])

				// Process orders data according to API spec format - handle both nested and direct data formats
				let orders = ordersResponse.data?.data || ordersResponse.data || []
				
				// Filter orders to only include those with at least one shippable item
				orders = orders.filter(order => 
					order.line_items?.some(item => item.requires_shipping === true)
				)
				
				// Apply mappings to orders
				try {
					// Only do this if there are orders
					if (orders.length > 0) {
						// Extract all line items from all orders for mapping
						const lineItems = orders.flatMap(order => 
							order.line_items?.map(item => ({ 
								orderId: order.id, 
								title: item.title,
								variant: item.variant_title || ''
							})) || []
						)
						
						if (lineItems.length > 0) {
							// Get mapping results from API
							const mappingResponse = await clientApi.applyOrderMappings(lineItems)
							
							if (mappingResponse.data) {
								// Add mapping results to orders
								orders.forEach(order => {
									const mappedValues = mappingResponse.data[order.id]
									if (mappedValues) {
										order.mappedValues = mappedValues
									}
								})
							}
						}
					}
				} catch (mappingError) {
					// Continue with orders but without mappings
				}
				
				this.orders = orders
				this.stores = storesResponse.data || []
				this.retailers = (retailersResponse.data || []).sort((a, b) => {
					if(a.name < b.name) { return -1 }
					if(a.name > b.name) { return 1 }
					return 0
				})
				this.auditors = (auditorsResponse.data || []).sort((a, b) => {
					if(a.name < b.name) { return -1 }
					if(a.name > b.name) { return 1 }
					return 0
				})
				
				// Select first order if any exist
				if (this.orders.length > 0) {
					this.selectedOrder = 0
				}
			} catch (error) {
				this.$root.$emit('show-snackbar', {
					color: 'error',
					message: 'Failed to load orders and reference data: ' + (error.response?.data?.message || error.message)
				})
			} finally {
				this.loading = false
			}
		},
		detectEmailPackage(order) {
			// Check line items for email package
			if (!order.line_items) return false
			return order.line_items.some(item => 
				item.title.toLowerCase().includes('email') || 
				item.title.toLowerCase().includes('e-mail'))
		},
		detectWhatsAppPackage(order) {
			// Check line items for WhatsApp package
			if (!order.line_items) return false
			return order.line_items.some(item => 
				item.title.toLowerCase().includes('whatsapp') || 
				item.title.toLowerCase().includes('messaging'))
		},
		detectSportsPackage(order) {
			// Check line items for sports package
			if (!order.line_items) return false
			return order.line_items.some(item => 
				item.title.toLowerCase().includes('sport'))
		},
		async syncOrders() {
			try {
				this.loading = true
				
				// Use mock data for development if requested, otherwise use live API
				let response
				if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_USE_MOCK_DATA === 'true') {
					response = await clientApi.getMockUnfulfilledOrders()
				} else {
					// Try to get orders with fallback if unfulfilled is empty
					response = await clientApi.getOrdersWithFallback()
				}
				
				// Process orders data according to API spec format - handle both nested and direct data formats
				let orders = response.data?.data || response.data || []
				
				// Filter orders to only include those with at least one shippable item
				orders = orders.filter(order => 
					order.line_items?.some(item => item.requires_shipping === true)
				)
				
				// Apply mappings to orders
				try {
					// Only do this if there are orders
					if (orders.length > 0) {
						// Extract all line items from all orders for mapping
						const lineItems = orders.flatMap(order => 
							order.line_items?.map(item => ({ 
								orderId: order.id, 
								title: item.title,
								variant: item.variant_title || ''
							})) || []
						)
						
						if (lineItems.length > 0) {
							// Get mapping results from API
							const mappingResponse = await clientApi.applyOrderMappings(lineItems)
							
							if (mappingResponse.data) {
								// Add mapping results to orders
								orders.forEach(order => {
									const mappedValues = mappingResponse.data[order.id]
									if (mappedValues) {
										order.mappedValues = mappedValues
									}
								})
							}
						}
					}
				} catch (mappingError) {
					// Continue with orders but without mappings
				}
				
				this.orders = orders
				
				this.$root.$emit('show-snackbar', {
					color: 'success',
					message: `Successfully fetched ${this.orders.length} orders from Shopify`
				})
				
				// Select first order if any exist
				if (this.orders.length > 0) {
					this.selectedOrder = 0
				}
			} catch (error) {
				this.$root.$emit('show-snackbar', {
					color: 'error',
					message: 'Failed to fetch orders from Shopify: ' + (error.response?.data?.message || error.message)
				})
			} finally {
				this.loading = false
			}
		},
		async submitForm() {
			if (!this.$refs.form.validate()) {
				return
			}

			if (!this.selectedOrderData || !this.selectedOrderData.id) {
				this.$root.$emit('show-snackbar', {
					color: 'error',
					message: 'No order selected'
				})
				return
			}

			try {
				this.submitting = true
				
				// Create client record only
				const createResponse = await clientApi.createClient(this.currentOrder)
				if (!createResponse.data) {
					throw new Error('Failed to create client')
				}
				
				// Store client ID and order ID in data properties
				this.createdClientId = createResponse.data._id
				this.shopifyOrderId = this.selectedOrderData.id
				this.shopifyOrderName = this.selectedOrderData.name
				
				// Show success message with link to client page
				this.$root.$emit('show-snackbar', {
					color: 'success',
					message: 'Client record created successfully'
				})
				
				// Show dialog with Shopify link instead of automatically fulfilling
				this.showShopifyDialog = true
			} catch (error) {
				this.$root.$emit('show-snackbar', {
					color: 'error',
					message: 'Error: ' + (error.response?.data?.message || error.message || 'Failed to process order')
				})
			} finally {
				this.submitting = false
			}
		},
		refreshPage() {
			this.search = ''
			this.selectedOrder = null
			this.orders = []
			this.showShopifyDialog = false
			this.createdClientId = null
			this.shopifyOrderId = null
			this.shopifyOrderName = null
			this.getData()
		},
		
		getShopifyOrderUrl() {
			// Generate URL to Shopify admin orders page with the correct store
			
			// If we have a Shopify order ID, create a direct link
			if (this.shopifyOrderId) {
				return `https://admin.shopify.com/store/kosheros/orders/${this.shopifyOrderId}`
			}
			
			// If no ID, return link to all orders
			return `https://admin.shopify.com/store/kosheros/orders`
		}
	},
	created () {
		this.getData()
	}
}
</script>

<style scoped>
/* Regular subtitle styling for most elements */
.v-list-item__subtitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Custom styling for line item descriptions to allow full visibility */
.line-item-subtitle {
  white-space: normal !important;
  overflow: visible !important;
  text-overflow: clip !important;
  word-break: break-word !important;
  margin-top: 4px !important;
  border-radius: 4px !important;
  padding: 4px 8px !important;
}

/* Different colors for primary (first) and secondary line items */
.line-item-primary {
  background-color: rgba(33, 150, 243, 0.1) !important;
  border-left: 3px solid #2196F3 !important;
}

.line-item-secondary {
  background-color: rgba(156, 39, 176, 0.05) !important;
  border-left: 3px solid #9C27B0 !important;
}

/* Make sure customer info remains on one line */
.customer-info {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>