<template>
    <v-form ref="form" lazy-validation>
        <v-card>
            <v-card-text>
                <v-layout wrap>
                    <!-- Basic Info -->
                    <v-flex xs6>
                        <v-text-field outlined v-model="formattedFirstName" label="First Name" ref="firstName"
                            class="pr-md-2" :rules="[rules.required]"></v-text-field>
                    </v-flex>
                    <v-flex xs6>
                        <v-text-field outlined v-model="formattedLastName" label="Last Name" ref="lastName"
                            class="pr-md-2" :rules="[rules.required]"></v-text-field>
                    </v-flex>

                    <!-- Contact Info -->
                    <v-flex xs12>
                        <v-text-field outlined v-model="clientData.email" label="Email" ref="email"
                            :rules="[rules.email]"></v-text-field>
                    </v-flex>

                    <!-- Account Info -->
                    <v-flex xs12>
                        <v-text-field outlined v-model="clientData.serialNo" label="Account Number" ref="accountNumber"
                            :rules="[rules.serialNumber]"></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                        <v-text-field outlined v-model="clientData.imei" label="IMEI" ref="imei"
                            :rules="[rules.imei]"></v-text-field>
                    </v-flex>

                    <!-- Alt Emails -->
                    <v-flex xs12>
                        <div v-for="(email, index) in clientData.altEmails" :key="index">
                            <v-text-field outlined v-model="clientData.altEmails[index]"
                                :label="`Alt Email ${index + 1}`" ref="altEmails" :rules="[rules.email]"
                                prepend-icon="mdi-plus" append-icon="mdi-minus" @click:prepend="addAltEmail"
                                @click:append="removeAltEmail(index)"></v-text-field>
                        </div>
                    </v-flex>

                    <!-- Selects -->
                    <v-flex xs12 md6>
                        <v-select v-model="clientData.store" :items="stores" item-text="name" item-value="_id"
                            label="Store" class="pr-md-2" outlined></v-select>
                    </v-flex>

                    <v-flex xs12 md6>
                        <v-select v-model="clientData.retailer" :items="retailers" item-text="name" item-value="_id"
                            label="Retailer" class="pl-md-2" outlined></v-select>
                    </v-flex>

                    <v-flex xs12 md6>
                        <v-select v-model="clientData.auditor" :items="auditors" item-text="name" item-value="_id"
                            label="Auditor" class="pl-md-2" outlined></v-select>
                    </v-flex>

                    <!-- Apps -->
                    <v-flex xs12 md6>
                        <v-autocomplete v-model="clientData.excludedApps" :items="apps" item-text="metadata.name"
                            item-value="metadata.packageName" label="Excluded Apps" class="pl-md-2" outlined clearable
                            chips deletable-chips multiple></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md6>
                        <v-autocomplete v-model="clientData.addedApps" :items="apps" item-text="metadata.name"
                            item-value="metadata.packageName" label="Added Apps" class="pl-md-2" outlined clearable
                            chips deletable-chips multiple></v-autocomplete>
                    </v-flex>

                    <!-- Notes -->
                    <v-flex xs12>
                        <v-textarea outlined v-model="clientData.accountNotes" label="Account Notes"
                            ref="accountNotes"></v-textarea>
                    </v-flex>

                    <!-- Switches -->
                    <v-row justify="space-around">
                        <v-flex xs12 md3>
                            <v-switch label="Include Email" v-model="clientData.emailPackage"></v-switch>
                        </v-flex>
                        <v-flex xs12 md3>
                            <v-switch label="Include WhatsApp" v-model="clientData.whatsApp"></v-switch>
                        </v-flex>
                        <v-flex xs12 md3>
                            <v-switch label="Include Android Auto" v-model="clientData.autoEntitlement"></v-switch>
                        </v-flex>
                        <v-flex xs12 md3>
                            <v-switch label="Include Sports" v-model="clientData.sportsPackage"></v-switch>
                        </v-flex>
                        <v-flex xs12 md3>
                            <v-switch label="Beta Tester" v-model="clientData.betaTester"></v-switch>
                        </v-flex>
                        <v-flex xs12 md3>
                            <v-switch label="Active Account" v-model="clientData.isActive"></v-switch>
                        </v-flex>
                        <v-flex xs12 md3>
                            <v-switch label="ADB Entitlement" v-model="clientData.adbEntitlement"></v-switch>
                        </v-flex>
                        <v-flex xs12 md3>
                            <v-switch label="Finance Ban" v-model="clientData.financeBan"></v-switch>
                        </v-flex>
                        <v-flex xs12 md3>
                            <v-switch label="Allow Google Maps" v-model="clientData.mapsAllowed"></v-switch>
                        </v-flex>
                        <v-flex xs12 md3>
                            <v-switch label="Allow Hotspot" v-model="clientData.allowHotspot"></v-switch>
                        </v-flex>
                        <v-flex xs12 md3>
                            <v-switch label="Disable Video Playback"
                                v-model="clientData.disallowVideoPlayback"></v-switch>
                        </v-flex>
                    </v-row>
                </v-layout>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
export default {
    name: 'ClientInfoForm',

    props: {
        value: {
            type: Object,
            required: true
        },
        stores: {
            type: Array,
            default: () => []
        },
        retailers: {
            type: Array,
            default: () => []
        },
        auditors: {
            type: Array,
            default: () => []
        },
        apps: {
            type: Array,
            default: () => []
        },
        rules: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            firstName: '',
            lastName: '',
            clientData: this.value
        }
    },

    computed: {
        formattedFirstName: {
            get() {
                return this.firstName
            },
            set(value) {
                this.firstName = this.toTitleCase(value.trim())
                this.updateName()
            }
        },

        formattedLastName: {
            get() {
                return this.lastName
            },
            set(value) {
                this.lastName = this.toTitleCase(value.trim())
                this.updateName()
            }
        }
    },

    watch: {
        value: {
            handler(newVal) {
                this.clientData = newVal
                if (newVal.name) {
                    const nameComponents = newVal.name.split(" ")
                    this.firstName = nameComponents.slice(0, -1).join(" ")
                    this.lastName = nameComponents[nameComponents.length - 1]
                }
            },
            immediate: true
        },

        clientData: {
            handler(newVal) {
                this.$emit('input', newVal)
            },
            deep: true
        }
    },

    methods: {
        toTitleCase(str) {
            return str.replace(/\w\S*/g, txt =>
                txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
            )
        },

        updateName() {
            this.clientData.name = `${this.firstName} ${this.lastName}`.trim()
        },

        addAltEmail() {
            if (!this.clientData.altEmails) {
                this.$set(this.clientData, 'altEmails', [])
            }
            this.clientData.altEmails.push("")
        },

        removeAltEmail(index) {
            this.clientData.altEmails.splice(index, 1)
        },

        validate() {
            return this.$refs.form.validate()
        },

        reset() {
            this.$refs.form.reset()
        }
    }
}
</script>