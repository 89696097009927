<template>
  <div>
    <v-toolbar dark color="secondary">
      <v-btn dark icon to="/activitygroups"><v-icon>arrow_back</v-icon></v-btn>
      <v-toolbar-title class="white--text">{{ formTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          dark
          text
          v-if="this.editing"
          @click.native="deleteItem(currentItem._id)"
          ><v-icon>delete</v-icon></v-btn
        >
        <v-btn dark text @click.native="saveItem"><v-icon>save</v-icon></v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card flat style="height: 80vh; overflow-y: scroll">
      <v-container grid-list-xl>
        <v-layout wrap>
          <v-flex xs12>
            <v-text-field
              v-model="currentItem.name"
              label="Name"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="currentItem.description"
              label="Description"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-container>
      <v-container>
        <h3>Activities</h3>
        <v-container>
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-row dense>
            <v-col
              v-for="activity in this.activities"
              :key="activity"
              cols="12"
              dense
            >
              <v-checkbox
                v-model="currentItem.activities"
                :label="activity.name"
                :value="activity._id"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      editing: true,
      loading: true,
      headers: [
        { text: "Edit", value: "edit", sortable: false },
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
      ],
      activities: [],
      currentItem: {
        _id: "",
        name: "",
        description: "",
        activities: [],
      },
    };
  },
  computed: {
    formTitle() {
      return !this.editing ? "New Activity Group" : "Edit Activity Group";
    },
  },
  methods: {
    initData() {
      if (!this.editing) {
        this.currentItem = {
          name: "",
          description: "",
          activities: [],
        };
      } else {
        axios
          .get(
            `https://api.safetelecom.net/api/activitygroups/` +
              this.$route.params.id
          )
          .then((response) => {
            this.currentItem = response.data;
          });
      }
      
      this.loading = true;
      axios
        .get(`https://api.safetelecom.net/api/activities`)
        .then((response) => {
          this.activities = response.data.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });

          this.loading = false;
        });
    },
    editItem(id) {
      this.editing = true;
      axios
        .get(`https://api.safetelecom.net/api/activitygroups/${id}`)
        .then((response) => {
          this.currentItem = response.data;
        });
      this.dialog = true;
    },
    saveItem() {
      if (this.$route.params.id === "new") {
        axios
          .post(
            `https://api.safetelecom.net/api/activitygroups/`,
            this.currentItem
          )
          .then(() => {
            this.$router.push("/activitygroups");
          });
      } else {
        axios
          .put(
            `https://api.safetelecom.net/api/activitygroups/${this.currentItem._id}`,
            this.currentItem
          )
          .then(() => {
            this.$router.push("/activitygroups");
          });
      }
    },
    deleteItem(id) {
      if (this.currentItem._id !== "60dde9659aefe3003abab569") {
      confirm("Are you sure you want to delete this item?") &&
        axios
          .delete(`https://api.safetelecom.net/api/activitygroups/${id}`)
          .then(() => {
            this.$router.push("/activitygroups");
          });
      }
      else {
        alert("Default BlockList cannot be deleted.")
      }
    },
  },
  created() {
    if (this.$route.params.id === "new") {
      this.editing = false;
    }
    this.initData();
  },
};
</script>
