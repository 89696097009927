<template>
  <v-dialog :value="value" @input="$emit('input', $event)" persistent max-width="500">
    <v-card>
      <v-card-title class="headline">
        {{ dialogTitle }}
      </v-card-title>

      <v-card-text>
        <v-container>
          <div v-if="saveError" class="red--text">
            Failed to save changes: {{ saveError }}
          </div>
          <div v-else-if="pushError" class="red--text">
            Failed to push changes: {{ pushError }}
          </div>
          <div v-else-if="completed" class="green--text">
            All changes have been saved successfully.
          </div>
          <div v-else-if="showPushOption">
            Would you like to push these changes directly to the device?
          </div>
          <div v-else class="d-flex align-center">
            <v-progress-circular indeterminate size="24" class="mr-2" color="primary"></v-progress-circular>
            <span>Saving your changes...</span>
          </div>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <template v-if="showPushOption && !completed && !pushError">
          <v-btn text @click="skipPush">
            Skip
          </v-btn>
          <v-btn color="primary" :loading="isPushing" @click="$emit('push-changes')">
            Push to Device
          </v-btn>
        </template>

        <template v-if="completed || saveError || pushError">
          <v-btn text @click="$emit('continue-editing')">
            Continue Editing
          </v-btn>
          <v-btn color="primary" @click="$emit('navigate-to-clients')">
            Return to Clients
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SaveProgressDialog',

  props: {
    value: {
      type: Boolean,
      default: false
    },
    canPushChanges: {
      type: Boolean,
      default: false
    },
    isSaving: {
      type: Boolean,
      default: false
    },
    isPushing: {
      type: Boolean,
      default: false
    },
    saveError: {
      type: String,
      default: null
    },
    pushError: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      showPushOption: false,
      completed: false
    }
  },

  computed: {
    dialogTitle() {
      if (this.saveError) return 'Error Saving Changes'
      if (this.pushError) return 'Error Pushing Changes'
      if (this.completed) return 'Changes Saved Successfully'
      if (this.showPushOption) return 'Push Changes to Device?'
      return 'Saving Changes...'
    }
  },
  // In SaveProgressDialog.vue, update the watch section:
  watch: {
    value(newVal) {
      if (newVal) {
        this.showPushOption = false
        this.completed = false
      }
    },

    isSaving(newVal) {
      if (!newVal && !this.saveError) {
        if (this.canPushChanges && !this.completed) {
          this.showPushOption = true
        } else {
          this.completed = true
        }
      }
    },

    isPushing(newVal) {
      if ((!this.showPushOption || !newVal) && !this.saveError && !this.pushError) {
        this.completed = true
      }
    }
  },

  methods: {
    skipPush() {
      this.showPushOption = false
      this.completed = true
    }
  }
}
</script>