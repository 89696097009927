import axios from 'axios'

const client = axios.create({
    baseURL: 'https://api.safetelecom.net/api',
})

// API proxy client for Shopify integration
const shopifyClient = axios.create({
    baseURL: 'https://api.safetelecom.net/api/shopify-proxy'
})

export const clientApi = {
    // Account API
    getCurrentAccount() {
        return client.get(`/account`);
    },

    getClients() {
        return client.get('/clients')
    },
    
    getClientsByRetailer(retailerId) {
        return client.get('/clients/byretailer/' + retailerId)
    },
    
    searchClients(searchQuery = '') {
        const endpoint = searchQuery 
            ? `/clients/search?q=${encodeURIComponent(searchQuery)}` 
            : '/clients/search'
        
        return client.get(endpoint)
    },

    searchByIMEI(imei) {
        if (!imei) return Promise.resolve({ data: [] })
        return client.get(`/clients/search/imei/${encodeURIComponent(imei)}`)
    },

    searchBySerial(serial) {
        if (!serial) return Promise.resolve({ data: [] })
        return client.get(`/clients/search/serial/${encodeURIComponent(serial)}`)
    },

    getClientById(id) {
        return client.get('/clients/' + id)
    },
    
    // Alias for getClientById for compatibility
    getClient(id) {
        return this.getClientById(id)
    },

    createClient(clientData) {
        return client.post('/clients', clientData)
    },

    updateClientById(id, clientData) {
        return client.put('/clients/' + id, clientData)
    },
    
    // Alias for updateClientById for compatibility
    updateClient(id, clientData) {
        return this.updateClientById(id, clientData)
    },
    
    deleteClientById(id) {
        return client.delete('/clients/' + id)
    },
    
    // Alias for deleteClientById for compatibility
    deleteClient(id) {
        return this.deleteClientById(id)
    },

    // Activity API
    getActivities() {
        return client.get('/activities')
    },

    getActivityById(id) {
        return client.get('/activities/' + id)
    },

    updateActivityById(id, activityData) {
        return client.put('/activities/' + id, activityData)
    },

    // ActivityGroups API
    getActivityGroups() {
        return client.get('/activitygroups')
    },

    getActivityGroupById(id) {
        return client.get('/activitygroups/' + id)
    },

    updateActivityGroupById(id, groupData) {
        return client.put('/activitygroups/' + id, groupData)
    },

    // Devices API
    getDevices() {
        return client.get('/devices')
    },

    getDeviceById(id) {
        return client.get('/devices/' + id)
    },

    // Apps API
    getApps() {
        return client.get('/apps')
    },

    getAppById(id) {
        return client.get('/apps/' + id)
    },

    updateAppById(id, appData) {
        return client.put('/apps/' + id, appData)
    },

    // BannedApps API
    getBannedApps() {
        return client.get('/bannedapps')
    },

    // TODO fix the API route for banned apps
    getBannedAppById(id) {
        return client.get('/apps/banned/' + id)
    },

    // Updates API
    getUpdates() {
        return client.get('/updates')
    },

    getUpdateById(id) {
        return client.get('/updates/' + id)
    },

    // Urls API
    getUrls() {
        return client.get('/browserwhitelist')
    },

    getUrlById(id) {
        return client.get('/browserwhitelist/' + id)
    },

    // Stores API
    getStores(accessLevel = null) {
        const endpoint = accessLevel ? `/stores?access=${accessLevel}` : '/stores'
        return client.get(endpoint)
    },

    getStoreById(id) {
        return client.get('/stores/' + id)
    },

    updateStoreById(id, storeData) {
        return client.put('/stores/' + id, storeData)
    },

    // Retailers API
    getRetailers() {
        return client.get('/retailers')
    },

    getRetailerById(id) {
        return client.get('/retailers/' + id)
    },

    updateRetailerById(id, retailerData) {
        return client.put('/retailers/' + id, retailerData)
    },

    // Auditors API
    getAuditors() {
        return client.get('/auditors')
    },

    getAuditorById(id) {
        return client.get('/auditors/' + id)
    },

    updateAuditorById(id, auditorData) {
        return client.put('/auditors/' + id, auditorData)
    },

    // Audit logs
    getAuditLogs(filters = {}) {
        const params = new URLSearchParams()
        for (const [key, value] of Object.entries(filters)) {
            if (value) {
                params.append(key, value)
            }
        }
        return client.get(`/auditlogs?${params.toString()}`)
    },

    // Reports
    getDuplicates() {
        return client.get('/reports/duplicates')
    },

    // FCM messaging
    sendFcmMessage(messageData) {
        // Use the absolute URL for messaging endpoints since they might be mounted differently
        return axios.post('https://api.safetelecom.net/messaging/send-message', messageData);
    },
    
    // Get FCM project IDs
    getProjectIds() {
        // Use the absolute URL for messaging endpoints since they might be mounted differently
        return axios.get('https://api.safetelecom.net/messaging/service-accounts')
            .catch(error => {
                console.warn('Error fetching project IDs:', error.message);
                return Promise.resolve({
                    data: ['default-fcm-project']
                });
            });
    },
    
    // Mock FCM project IDs for development/testing
    getMockProjectIds() {
        return Promise.resolve({
            data: ['safetelecom-production', 'safetelecom-development']
        });
    },
    
    // Get carrier information
    getCarriers() {
        return axios.get('https://raw.githubusercontent.com/elisam98/mcc-mnc-table/master/mcc-mnc-table.json')
    },

    // Order Mappings API
    getOrderMappings() {
        return client.get('/settings/order-mappings')
    },
    
    saveOrderMappings(mappings) {
        return client.post('/settings/order-mappings', mappings)
    },
    
    applyOrderMappings(lineItems) {
        return client.post('/settings/apply-mappings', { lineItems })
    },
    
    // Mock methods for development
    getMockOrderMappings() {
        // Simulate API response with mock data
        return Promise.resolve({
            data: {
                store: [
                    {
                        _id: "mock-store-mapping-1",
                        targetId: "5a0d9be20823dc23dfcacc18", // SafeTelecom Basic
                        patterns: ["basic", "standard"]
                    }
                ],
                auditor: [
                    {
                        _id: "mock-auditor-mapping-1",
                        targetId: "5a0d9be20823dc23dfcacc19", // None
                        patterns: ["basic", "standard"]
                    }
                ],
                feature: [
                    {
                        _id: "mock-feature-mapping-1",
                        targetValue: "emailPackage",
                        patterns: ["email", "e-mail"]
                    },
                    {
                        _id: "mock-feature-mapping-2",
                        targetValue: "whatsApp",
                        patterns: ["whatsapp", "messaging"]
                    }
                ]
            }
        });
    },
    
    getMockUnfulfilledOrders() {
        // Simulate API response with mock data
        return Promise.resolve({
            data: [
                {
                    id: "mock-order-1",
                    name: "#1001",
                    created_at: new Date().toISOString(),
                    total_price: "129.99",
                    customer: {
                        first_name: "John",
                        last_name: "Doe",
                        email: "john.doe@example.com",
                        phone: "+12025550179"
                    },
                    line_items: [
                        {
                            id: "mock-line-item-1",
                            title: "SafeTelecom Basic Package",
                            variant_title: "Monthly",
                            quantity: 1
                        },
                        {
                            id: "mock-line-item-2",
                            title: "Email Add-on",
                            variant_title: null,
                            quantity: 1
                        }
                    ]
                },
                {
                    id: "mock-order-2",
                    name: "#1002",
                    created_at: new Date().toISOString(),
                    total_price: "199.99",
                    customer: {
                        first_name: "Jane",
                        last_name: "Smith",
                        email: "jane.smith@example.com",
                        phone: "+12025550143"
                    },
                    line_items: [
                        {
                            id: "mock-line-item-3",
                            title: "SafeTelecom Premium Package",
                            variant_title: "Annual",
                            quantity: 1
                        },
                        {
                            id: "mock-line-item-4",
                            title: "WhatsApp Add-on",
                            variant_title: null,
                            quantity: 1
                        }
                    ]
                }
            ]
        });
    },
    
    mockFulfillOrder(/* orderId, clientData */) {
        // Simulate API response for mock order fulfillment
        // Parameters are unused since this is a mock implementation
        return Promise.resolve({
            data: {
                success: true,
                message: 'Order successfully fulfilled'
            }
        });
    },

    // Shopify integration - using API proxy as per SHOPIFY_API_PROXY_SPEC.md
    testShopifyConnection() {
        // eslint-disable-next-line no-useless-catch
        try {
            return shopifyClient.get('/test')
        } catch (error) {
            throw error
        }
    },

    getUnfulfilledOrders() {
        // eslint-disable-next-line no-useless-catch
        try {
            // Fetch unfulfilled orders through the API proxy
            return shopifyClient.get('/orders', {
                params: {
                    status: 'open',
                    fulfillment_status: 'unfulfilled'
                }
            })
        } catch (error) {
            throw error
        }
    },

    async getOrdersWithFallback() {
        // eslint-disable-next-line no-useless-catch
        try {
            // Try unfulfilled orders first
            const unfulfilledResponse = await this.getUnfulfilledOrders()
            if ((unfulfilledResponse.data?.data && unfulfilledResponse.data.data.length > 0) || 
                (unfulfilledResponse.data && Array.isArray(unfulfilledResponse.data) && unfulfilledResponse.data.length > 0)) {
                return unfulfilledResponse
            }
            
            // If no unfulfilled orders, fall back to all orders
            const allOrdersResponse = await this.getAllOrders({ limit: 10 })
            return allOrdersResponse
        } catch (error) {
            throw error
        }
    },

    getAllOrders(params = {}) {
        // eslint-disable-next-line no-useless-catch
        try {
            // Fetch all orders with optional filtering
            return shopifyClient.get('/orders', { params })
        } catch (error) {
            throw error
        }
    },

    getOrderById(orderId) {
        // eslint-disable-next-line no-useless-catch
        try {
            // Fetch a specific order by ID
            return shopifyClient.get(`/orders/${orderId}`)
        } catch (error) {
            throw error
        }
    },

    fulfillOrder(orderId, clientData) {
        // eslint-disable-next-line no-useless-catch
        try {
            // Create a fulfillment for the order with client tracking info
            return shopifyClient.post(`/orders/${orderId}/fulfill`, clientData)
        } catch (error) {
            throw error
        }
    }
}