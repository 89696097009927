<template>
	<div>
		<v-toolbar dark color="secondary">
			<v-toolbar-title class="white--text">Duplicates</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn icon rounded dark @click="refreshPage">
				<v-icon>refresh</v-icon>
			</v-btn>
		</v-toolbar>
		<v-card v-if="selectedClients.length > 0">
			<v-card-text>
				<v-btn color="primary" @click="deleteSelected">Delete Selected</v-btn>
			</v-card-text>
		</v-card>
		<v-card v-for="record in clients" v-bind:key="record._id">
			<v-card-title>{{record._id}}</v-card-title>
			<v-card-text style="overflow-x: auto">
				<v-data-table
					dense
					v-model="selectedClients"
					show-select
					:mobile-breakpoint="mobileBreakpoint"
					:loading="loading"
					:headers="headers"
					:items="record.records"
					:sort-by="['lastUpdated']"
					:sort-desc="[true]"
					:items-per-page="25"
					item-key="_id"
					must-sort
					class="elevation-1"
					hide-default-footer
				>
					<template v-slot:[`item.serialNo`]="{ item }">
						<v-btn icon small class="mr-1" :to="{ name: 'Client', params: { id: item._id } }">
							<v-icon small>launch</v-icon>
						</v-btn>
						{{ item.serialNo}}
					</template>
					<template v-slot:[`item.lastUpdated`]="{ item }">{{ moment(item.lastUpdated).format('M/D/YYYY h:mm a') }}</template>
					<template v-slot:[`item.lastCheckIn`]="{ item }">{{ moment(item.lastCheckIn).calendar(null, { sameElse: 'M-D-YYYY h:mm:ss a' }) }}</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'

export default {
	name: "DuplicateList",
	data () {
		return {
			loading: true,
			selectedClients: [],
			mobileBreakpoint: 0,
			moment: moment,
			serialSearch: '',
			headers: [
				{ text: 'Name', value: 'name' },
				{ text: 'Added', value: 'lastUpdated' },
				{ text: 'MCC', value: 'mcc' },
				{ text: 'Checked In', value: 'lastCheckIn' },
			],
			clients: [],
		}
	},
	methods: {
		getClients () {
			this.loading = true
			axios.get(`https://api.safetelecom.net/api/duplicates`)
			.then(response => {
				this.clients = response.data.slice(0, 25)
				this.loading = false
			})
		},
		refreshPage () {
			this.clients = []
			this.getClients()
		},
		deleteSelected () {
			var selectedIds = this.selectedClients.map(a => a._id)
			var confirmDelete = confirm('Are you sure you want to delete?')
			if (confirmDelete == true) {
				selectedIds.forEach(id => {
					axios.delete(`https://api.safetelecom.net/api/clients/${id}`).then(() => {
					})
				})
				this.refreshPage()
			} else {
				alert("Canceled")
			}
		},
		clearFilter () {
			this.serialSearch = ''
		}
	},
	created () {
		this.getClients()
	}
}
</script>
