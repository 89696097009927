<template>
    <v-card>
        <v-list style="column-count: 2;">
            <v-list-item v-for="app in apps" :key="app">
                <v-list-item-content>
                    <v-list-item-title>{{ app }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
export default {
    name: 'ClientInstalledApps',

    props: {
        apps: {
            type: Array,
            default: () => []
        }
    }
}
</script>