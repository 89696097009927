<template>
    <div>
        <v-toolbar dark color="secondary">
            <v-toolbar-title class="white--text">URLs</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn icon rounded dark @click="refreshPage">
                <v-icon>refresh</v-icon>
            </v-btn>
            <v-btn icon rounded dark to="/urls/new">
                <v-icon>add</v-icon>
            </v-btn>
        </v-toolbar>

        <v-expansion-panels v-model="filterPanel">
            <v-expansion-panel>
                <v-expansion-panel-header>Filter</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-card>
                        <v-card-text>
                            <v-container fluid grid-list-lg>
                                <v-layout row wrap>
                                    <v-flex xs12 md2>
                                        <v-text-field v-model="hostSearch" hide-details clearable outlined label="Host"
                                            single-line></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 md3>
                                        <v-text-field v-model="pathSearch" hide-details clearable outlined label="Path"
                                            single-line></v-text-field>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="clearFilter()">Clear All</v-btn>
                            <v-btn color="primary" text @click="filterPanel = []">Apply</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-card v-if="selectedUrls.length > 0">
            <v-card-text>
                <v-btn color="primary" @click="deleteSelected">Delete Selected</v-btn>
            </v-card-text>
        </v-card>

        <v-card>
            <v-card-text style="overflow-x: auto">
                <v-data-table dense v-model="selectedUrls" show-select :mobile-breakpoint="mobileBreakpoint"
                    :loading="loading" :headers="this.headers" :items="filteredUrls" :footer-props="{
                        'items-per-page-options': [10, 25, 50, 100, { 'text': 'All', 'value': -1 }]
                    }" :sort-desc="[true]" :items-per-page="25" item-key="_id" must-sort class="elevation-1">
                    <template v-slot:[`item.hostname`]="{ item }">
						<v-btn icon small class="mr-1" :to="'urls/' + item._id">
							<v-icon small>launch</v-icon>
						</v-btn>
						{{ item.hostname}}
					</template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'UrlsList',
    data() {
        return {
            loading: true,
            urls: [],
            selectedUrls: [],
            filterPanel: [],
            hostSearch: '',
            pathSearch: '',
            mobileBreakpoint: 0,
            headers: [{ text: 'Host', value: 'hostname' }, { text: 'Path', value: 'pathname' }]
        }
    },
    computed: {
        filteredUrls() {
            let urls = this.urls

            if (this.hostSearch) {
                urls = urls.filter(client => client.hostname.includes(this.hostSearch))
            }
            if (this.pathSearch) {
                urls = urls.filter(client => client.pathname.includes(this.pathSearch))
            }

            return urls
        }
    },
    methods: {
        getUrls() {
            this.loading = true
            axios.get(`https://api.safetelecom.net/api/browserwhitelist`)
                .then(response => {
                    this.urls = response.data
                    this.loading = false
                })
        },
        deleteSelected() {
            var selectedIds = this.selectedUrls.map(a => a._id)
            var confirmDelete = confirm('Are you sure you want to delete?')
            if (confirmDelete == true) {
                selectedIds.forEach(id => {
                    axios.delete(`https://api.safetelecom.net/api/browserwhitelist/${id}`).then(() => {
                        if (selectedIds.indexOf(id) === selectedIds.length - 1) {
                            this.refreshPage()
                        }
                    })

                })
            } else {
                alert("Canceled")
            }
        },
        refreshPage() {
            this.urls = []
            this.getUrls()
        },
        clearFilter() {
            this.hostSearch = '',
                this.pathSearch = ''
        },
    },
    created() {
        this.getUrls()
    }
}
</script>