<template>
  <div>
    <v-app-bar dark color="secondary">
      <v-toolbar-title class="white--text">Retailers</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn rounded @click="refreshPage">
        <v-icon>refresh</v-icon>
      </v-btn>
      <v-btn rounded icon dark to="/retailers/new">
        <v-icon>add</v-icon>
      </v-btn>
    </v-app-bar>
    <v-expansion-panels v-model="filterPanel">
      <v-expansion-panel>
        <v-expansion-panel-header>Filter</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card>
            <v-card-text>
              <v-container fluid grid-list-lg>
                <v-layout row wrap>
                  <v-flex xs12 md3>
                    <v-select
                      single-line
                      clearable
                      outlined
                      v-model="roleSearch"
                      :items="roles"
                      item-text="role"
                      item-value="role"
                      label="Role"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="clearFilter()">Clear All</v-btn>
              <v-btn color="primary" text @click="filterPanel = []"
                >Apply</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-container>
      <v-card>
        <v-data-table
          dense
          :loading="loading"
          :headers="headers"
          :items="filteredRetailers"
          :sort-by="['name']"
          :sort-desc="[false]"
          :items-per-page="-1"
          item-key="_id"
          must-sort
          class="elevation-1"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              small
              rounded
              icon
              class="mr-2"
              :to="'retailers/' + item._id"
            >
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn
              small
              rounded
              icon
              class="mr-2"
              @click="deleteItem(item._id)"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "RetailerList",
  data() {
    return {
      loading: true,
      filterPanel: [],
      roleSearch: "",
      headers: [
        { text: "Actions", value: "actions", sortable: false },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Role", value: "role" },
      ],
      items: [],
      roles: [],
    };
  },
  computed: {
    filteredRetailers() {
      const retailers = this.items;
      if (this.roleSearch) {
        return retailers.filter((retailer) =>
          retailer.role ? retailer.role === this.roleSearch : false
        );
      }
      return retailers;
    },
  },
  methods: {
    getItems() {
      this.loading = true;
      axios
        .get(`https://api.safetelecom.net/api/retailers`)
        .then((response) => {
          this.items = response.data;
          this.roles = [...new Set(this.items.map((item) => item.role))];
          this.loading = false;
        });
    },
    deleteItem(id) {
      confirm("Are you sure you want to delete this item?") &&
        axios
          .delete(`https://api.safetelecom.net/api/retailers/${id}`)
          .then(() => {
            this.refreshPage();
          });
    },
    refreshPage() {
      this.items = [];
      this.getItems();
    },
    clearFilter() {
    this.roleSearch = "";
  },
  },
  created() {
    this.getItems();
  },
  
};
</script>
